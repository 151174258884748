import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Avatar, Button, Select } from 'antd'
import { SendOutlined } from '@ant-design/icons'
import { sendInvoice, getOwner } from '../../services/api'
import { notifySuccess, notifyError } from '../notification'
import Languages from '../languages'
import { getOwnerName } from '../owners'

const LanguagesOptionList = Languages.map((type, idx) => <Select.Option key={`${idx}`} value={type.value}>{type.label}</Select.Option>)

const SendInvoiceModal = ({ currentUser, language, invoice, onOk, onCancel, ...rest }) => {
    const [loading, setLoading] = useState(false)
    const [owner, setOwner] = useState(null)
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        if (currentUser) {
            setLoading(true)
            try {
                const email = values.email
                const lang = values.lang
                const receiver_name = values.receiver_name
                const token = await currentUser.getIdToken()
                await sendInvoice({ token, id: invoice.id, email, lang, receiver_name })
                notifySuccess({ description: "Invoice has been sent successfuly" })
                setLoading(false)
                if (onOk) {
                    onOk();
                }
            } catch (err) {
                setLoading(false)
                notifyError({ description: "Fail to send an invoice to the customer emal" })
            }
        }
    }

    useEffect(async () => {
        if ((!owner) && currentUser && invoice && invoice.shop && invoice.shop.owner_id) {
            try {
                const token = await currentUser.getIdToken()
                const resp = await getOwner({ token, id: invoice.shop.owner_id })
                if (resp) {
                    setOwner(resp.data)
                } else {
                    setOwner(null)
                }
            } catch (err) {
                setOwner(null)
                notifyError({ description: "Fail to fetch shop owner" })
            }
        }
    }, [currentUser, invoice])

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
        form.resetFields()
    }

    return (
        <Modal
            onOk={form.submit}
            onCancel={handleOnCancel}
            footer={[
                <Button key="cancel" onClick={handleOnCancel}>
                    Cancel
                </Button>,
                <Button key="ok" type="primary" loading={loading} onClick={form.submit}>
                    OK
                </Button>,
            ]}
            {...rest}
        >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "20px" }}>
                <Avatar size={96} icon={<SendOutlined />} className="aappoint-avatar-shadow .aappoint-avatar-default" />
            </div>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, message: 'Email address cannot be empty!' }]}
                    initialValue={(owner && owner.email) ? owner.email : undefined}
                >
                    <Input placeholder="Enter an email" />
                </Form.Item>
                <Form.Item
                    name="receiver_name"
                    label="Customer Name"
                    rules={[{ required: true, message: 'Name of the customer cannot be empty!' }]}
                    initialValue={(owner) ? getOwnerName(owner) : undefined}
                >
                    <Input placeholder="Enter the customer name" />
                </Form.Item>
                <Form.Item
                    name="lang"
                    label="Language"
                    initialValue={(owner && owner.preferred_lang) ? owner.preferred_lang : (language) ? language : 'en'}
                >
                    <Select
                        placeholder="Select a language"
                    >
                        {LanguagesOptionList}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default SendInvoiceModal;