import React, { useState, useEffect } from 'react'
import { Modal, Form, DatePicker, Button, InputNumber, Input, Tabs, Collapse, Row, Col, Popconfirm } from 'antd'
import { ControlOutlined, ShopOutlined, CaretRightOutlined, OrderedListOutlined, PlusOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { updateInvoice } from '../../services/api'
import { notifySuccess, notifyError } from '../notification'
import moment from 'moment'

const EditInvoiceModal = ({ currentUser, invoice, visible, onOk, onCancel, ...rest }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [editingInvoice, setEditingInvoice] = useState(null)

    useEffect(() => {
        if (invoice && visible) {
            var inv = Object.assign({}, invoice)
            inv.items = invoice.items.map(item => Object.assign({}, item))
            setEditingInvoice(inv)
        }
    }, [invoice, visible])

    const onFinish = async (values) => {
        if (currentUser && editingInvoice) {
            setLoading(true)

            const invoiceId = editingInvoice.id
            var inv = Object.assign({}, editingInvoice)

            if (values.date) {
                inv.date = values.date
            }
            if (values.due_date) {
                inv.due_date = values.due_date
            }
            if (values.service_month) {
                inv.service_month = values.service_month.format("YYYYMM")
            }
            if (values.tax_percent) {
                inv.tax_percent = `${values.tax_percent}`
            }
            if (!inv.billing_address) {
                inv.billing_address = {}
            }
            inv.billing_address.name = values.bill_to_name
            inv.billing_address.address = values.bill_to_address
            inv.billing_address.tax_id = values.bill_to_tax_id

            try {
                const token = await currentUser.getIdToken()
                await updateInvoice({ token, id: invoiceId, invoice: inv })
                notifySuccess({ description: "Invoice has been update successfully" })
                setLoading(false)
                if (onOk) {
                    onOk();
                }
                form.resetFields()
            } catch (err) {
                setLoading(false)
                notifyError({ description: "Fail to update an invoice" })
            }
        }
    }

    const onFinishFailed = ({ values, errorFields }) => {
        console.log("VALUES", values)
        console.log("FIELDS", errorFields)
    }

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
        setEditingInvoice(null)
        form.resetFields()
    }

    const onChangeItemDescription = (idx, e) => {
        if (editingInvoice && editingInvoice.items && idx >= 0 && idx < editingInvoice.items.length) {
            editingInvoice.items[idx].description = e.target.value
        }
    }

    const onChangeItemQty = (idx, value) => {
        if (editingInvoice && editingInvoice.items && idx >= 0 && idx < editingInvoice.items.length) {
            editingInvoice.items[idx].qty = value
        }
    }

    const onChangeItemUnitPrice = (idx, value) => {
        if (editingInvoice && editingInvoice.items && idx >= 0 && idx < editingInvoice.items.length) {
            editingInvoice.items[idx].unit_price = `${value}`
        }
    }

    const onChangeItemDiscount = (idx, value) => {
        if (editingInvoice && editingInvoice.items && idx >= 0 && idx < editingInvoice.items.length) {
            editingInvoice.items[idx].discount_amount = `${value}`
        }
    }

    const onAddItem = () => {
        if (editingInvoice) {
            const inv = Object.assign({}, editingInvoice)
            const item = {
                invoice_id: inv.id,
                qty: 0,
                unit_price: "0.00",
                discount_amount: "0.00",
                description: "",
            }
            if (inv.items) {
                inv.items.push(item)
            } else {
                inv.items = [
                    item
                ]
            }
            setEditingInvoice(inv)
        }
    }

    const onDeleteItem = (idx) => {
        if (editingInvoice && editingInvoice.items && idx !== undefined && idx >= 0 && idx < editingInvoice.items.length) {
            editingInvoice.items.splice(idx, 1)
            const inv = Object.assign({}, editingInvoice)
            setEditingInvoice(inv)
        }
    }

    const getPanels = () => {
        if (editingInvoice && editingInvoice.items) {
            return editingInvoice.items.map((item, i) => {
                const serviceMonth = (item.end_date) ? moment(item.end_date).format("MMM YYYY") : undefined
                const header = (item.description) ? item.description : serviceMonth ? `Service fees of ${serviceMonth}` : `Service fees`
                return (
                    <Collapse.Panel
                        header={
                            <>
                                <span>{`${i + 1}. `}</span>
                                {
                                    (item.description) ?
                                        <span>{header}</span> :
                                        <span className="aappoint-disabled-text">{header}</span>
                                }
                            </>
                        }
                        key={`${i}`}
                        extra={
                            <Popconfirm
                                title={`Are you sure to remove this item?`}
                                placement="left"
                                icon={<QuestionCircleOutlined />}
                                okText="Yes"
                                cancelText="No"
                                okType="danger"
                                onCancel={(event) => {
                                    event.stopPropagation()
                                }}
                                onConfirm={(event) => {
                                    event.stopPropagation()
                                    onDeleteItem(i)
                                }}
                            >
                                <Button type="text" size='small' danger icon={<DeleteOutlined />} onClick={event => event.stopPropagation()} />
                            </Popconfirm>
                        }
                    >
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    name={`item_description_${i}`}
                                    label="Description"
                                >
                                    <Input
                                        style={{ width: "100%" }}
                                        placeholder="Enter the description of the item"
                                        onChange={(e) => onChangeItemDescription(i, e)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <Form.Item
                                    name={`item_qty_${i}`}
                                    label="Unit"
                                    rules={[{ required: true, message: 'Please enter the unit of the item' }]}
                                >
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        min={0}
                                        placeholder="Enter the unit of the item"
                                        onChange={(value) => onChangeItemQty(i, value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name={`item_unit_price_${i}`}
                                    label="Unit Price (THB)"
                                    rules={[{ required: true, message: 'Please enter the unit price of the item' }]}
                                >
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        min={0}
                                        placeholder="Enter the unit price of the item"
                                        onChange={(value) => onChangeItemUnitPrice(i, value)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name={`item_discount_${i}`}
                                    label="Discount (THB)"
                                    rules={[{ required: true, message: 'Please enter the discount amount of the item' }]}
                                >
                                    <InputNumber
                                        min={0}
                                        style={{ width: "100%" }}
                                        placeholder="Enter the discount amount of the item"
                                        onChange={(value) => onChangeItemDiscount(i, value)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Collapse.Panel>
                )
            })
        }
        return <></>
    }

    if (editingInvoice) {
        const billingTo = (editingInvoice.billing_address) ? editingInvoice.billing_address : {
            name: "",
            address: "",
            tax_id: ""
        }

        var fields = {
            date: moment(editingInvoice.date),
            due_date: moment(editingInvoice.due_date),
            service_month: moment(editingInvoice.service_month),
            tax_percent: editingInvoice.tax_percent,
            bill_to_name: billingTo.name,
            bill_to_address: billingTo.address,
            bill_to_tax_id: billingTo.tax_id,
        }

        if (editingInvoice && editingInvoice.items) {
            for (let i = 0; i < editingInvoice.items.length; i++) {
                fields[`item_description_${i}`] = editingInvoice.items[i].description
                fields[`item_qty_${i}`] = editingInvoice.items[i].qty
                fields[`item_unit_price_${i}`] = editingInvoice.items[i].unit_price
                fields[`item_discount_${i}`] = editingInvoice.items[i].discount_amount
            }
        }

        form.setFieldsValue(fields);
    }

    return (
        <Modal
            onOk={form.submit}
            onCancel={handleOnCancel}
            visible={visible}
            footer={[
                <Button key="cancel" onClick={handleOnCancel}>
                    Cancel
                </Button>,
                <Button key="ok" type="primary" loading={loading} onClick={form.submit}>
                    OK
                </Button>,
            ]}
            {...rest}
        >
            <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Tabs>
                    <Tabs.TabPane
                        style={{ paddingTop: '16px' }}
                        tab={
                            <span>
                                <ShopOutlined />
                                Bill To
                            </span>
                        }
                        key="1"
                    >
                        <Form.Item
                            name="bill_to_name"
                            label="Name"
                            rules={[{ required: true, message: 'Please enter the name of the customer' }]}
                        >
                            <Input
                                style={{ width: "100%" }}
                                placeholder="Enter the name of the customer"
                            />
                        </Form.Item>
                        <Form.Item
                            name="bill_to_address"
                            label="Address"
                            rules={[{ required: true, message: 'Please enter the address of the customer' }]}
                        >
                            <Input.TextArea
                                style={{ width: "100%" }}
                                rows={3}
                                placeholder="Enter the address of the customer"
                            />
                        </Form.Item>
                        <Form.Item
                            name="bill_to_tax_id"
                            label="Tax ID"
                        >
                            <Input
                                style={{ width: "100%" }}
                                placeholder="Enter the tax ID of the customer"
                            />
                        </Form.Item>
                    </Tabs.TabPane>

                    <Tabs.TabPane
                        style={{ paddingTop: '16px' }}
                        tab={
                            <span>
                                <ControlOutlined />
                                Attributes
                            </span>
                        }
                        key="2"
                    >
                        <Form.Item
                            name="date"
                            label="Invoice Date"
                            rules={[{ required: true, message: 'Please enter invoice date' }]}
                        >
                            <DatePicker
                                style={{ width: "100%" }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="due_date"
                            label="Due Date"
                        >
                            <DatePicker
                                style={{ width: "100%" }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="service_month"
                            label="Service month"
                        >
                            <DatePicker
                                picker="month"
                                style={{ width: "100%" }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="tax_percent"
                            label="Tax rate (%)"
                            rules={[{ required: true, message: 'Please enter the VAT rate (%)' }]}
                        >
                            <InputNumber
                                min={0}
                                max={100}
                                style={{ width: "100%" }}
                                step="0.01"
                            />
                        </Form.Item>
                    </Tabs.TabPane>


                    <Tabs.TabPane
                        style={{ paddingTop: '16px' }}
                        tab={
                            <span>
                                <OrderedListOutlined />
                                Items
                            </span>
                        }
                        key="3"
                    >
                        <div style={{ height: '300px', overflow: 'auto' }}>
                            <Collapse
                                accordion={true}
                                destroyInactivePanel={false}
                                expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            >
                                {getPanels()}
                            </Collapse>
                        </div>
                        <div style={{ paddingTop: '24px' }}>
                            <Button type='primary' ghost block icon={<PlusOutlined />} onClick={onAddItem}>Add Item</Button>
                        </div>

                    </Tabs.TabPane>
                </Tabs>
            </Form>
        </Modal>
    )
}

export default EditInvoiceModal;