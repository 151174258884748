import React from 'react'
import { PageHeader as AntdPageHeader } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import { Link } from 'gatsby'

const PageHeader = ({ breadcrumb: { ...brest }, ...rest }) => {
    function itemRender(route, params, routes, paths) {
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? (
            <span>{route.path === "/" ? <HomeOutlined /> : route.breadcrumbName}</span>
        ) : (
            <Link to={route.path}>{route.path === "/" ? <HomeOutlined /> : route.breadcrumbName}</Link>
        );
    }
    return (
        <AntdPageHeader breadcrumb={{ itemRender, ...brest }} {...rest} />
    )
}

export default PageHeader;