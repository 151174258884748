import React, { useState } from 'react'
import { Modal, Form, Input, Select, Avatar, Button } from 'antd'
import { UserAddOutlined } from '@ant-design/icons'
import { addUser } from '../../services/api'
import Roles from '../roles'
import { notifySuccess, notifyError } from '../notification'

const RolesOptionList = Roles.map((type, idx) => <Select.Option key={`RoleKey${idx}`} value={type.value}>{type.label}</Select.Option>)

const NewUserModal = ({ currentUser, onOk, onCancel, ...rest }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const onFinish = async (values) => {
        if (currentUser) {
            setLoading(true)
            try {
                const role = values.role
                const email = values.email
                const token = await currentUser.getIdToken()
                await addUser({ token, email, role })
                notifySuccess({ description: "A new user has been added successfuly" })
                setLoading(false)
                if (onOk) {
                    onOk();
                }
            } catch (err) {
                setLoading(false)
                if (err.response && err.response.data && err.response.data.message && err.response.status >= 400 && err.response.status < 500) {
                    notifyError({ description: "Fail to add a new user.\n" + err.response.data.message })
                } else {
                    notifyError({ description: "Fail to add a new user" })
                }
            }
        }
    }

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    return (
        <Modal
            onOk={form.submit}
            onCancel={handleOnCancel}
            footer={[
                <Button key="cancel" onClick={handleOnCancel}>
                    Cancel
                </Button>,
                <Button key="ok" type="primary" loading={loading} onClick={form.submit}>
                    OK
                </Button>,
            ]}
            {...rest}
        >
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "20px" }}>
                <Avatar size={96} icon={<UserAddOutlined />} className="aappoint-avatar-shadow .aappoint-avatar-default" />
            </div>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, message: 'Please input an email of a new user!' }]}
                >
                    <Input placeholder="Enter an email of a new user" />
                </Form.Item>
                <Form.Item
                    name="role"
                    label="Role"
                >
                    <Select
                        placeholder="Select a role"
                        defaultValue="None"
                    >
                        {RolesOptionList}
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default NewUserModal;