import React, { useState, useCallback } from 'react';
import { Avatar, Button, Table, Tooltip, Popconfirm } from 'antd'
import { UserOutlined, EditOutlined, PhoneOutlined, MailOutlined, SafetyOutlined, DeleteOutlined, QuestionCircleOutlined, UserAddOutlined } from '@ant-design/icons'
import UserModal from './modals/user-modal'
import NewUserModal from './modals/new-user-modal'
import { useFirebase } from './firebase/context'
import { notifySuccess, notifyError } from './notification'
import { deleteUser, listUsers } from '../services/api'
import PageHeader from './page-header'
import { getColumnSearchProps } from './column-search'

const getUserName = (user) => {
    return (user.first_name) ?
        (user.last_name && user.last_name.length > 0) ?
            `${user.first_name} ${user.last_name}` :
            `${user.first_name}` :
        (user.last_name) ? `${user.last_name}` : ''
}

const columns = ({ currentUser, onAddUser, onEditUser, onDeleteUser }) => {
    return [
        {
            title: 'Name',
            key: 'name',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            render: (text, record) => {
                const avatar = (record.picture) ?
                    <Avatar src={record.picture} size={48} /> :
                    <Avatar icon={<UserOutlined />} size={48} />

                const name = getUserName(record)
                return (
                    <div style={{ display: 'inline-flex', justifyContent: 'justify-start', alignItems: 'center' }}>
                        {avatar}
                        {
                            (record.role) ?
                                (

                                    <div style={{ paddingLeft: '16px', flexDirection: 'column', display: 'flex' }}>
                                        <div style={{ display: "inline-flex" }}>
                                            {
                                                (name) ? <div style={{ paddingRight: "4px" }}>{name}</div> :
                                                    <div style={{ paddingRight: "4px" }}>N/A</div>
                                            }
                                            {
                                                (currentUser && currentUser.uid === record.id) && <div className="aappoint-secondary-color">(Me)</div>
                                            }
                                        </div>
                                        <div className="aappoint-sm-hidden">
                                            <small><div><SafetyOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{record.role}</span></div></small>
                                        </div>
                                        <div className="aappoint-xl-hidden">
                                            {
                                                (record.email !== undefined && record.email !== null && record.email.length > 0) ?
                                                    <small><a href={`mailto:${record.email}`}><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{record.email}</span></a></small> :
                                                    <small><div className="aappoint-secondary-color"><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div></small>
                                            }
                                        </div>
                                        <div className="aappoint-xl-hidden">
                                            {
                                                (record.phone_no !== undefined && record.phone_no !== null && record.phone_no.length > 0) ?
                                                    <small><a href={`tel:${record.phone_no}`}><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{record.phone_no}</span></a></small> :
                                                    <small><div className="aappoint-secondary-color"><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div></small>
                                            }
                                        </div>

                                    </div>
                                ) :
                                (
                                    <div className="aappoint-secondary-color" style={{ paddingLeft: '16px', flexDirection: 'column', display: 'flex' }}>
                                        <div>
                                            {
                                                (name) ? <div style={{ paddingRight: "4px" }}>{name}</div> :
                                                    <div style={{ paddingRight: "4px" }}>N/A</div>
                                            }
                                        </div>
                                        <div className="aappoint-sm-hidden">
                                            <small><div className="aappoint-secondary-color"><SafetyOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div></small>
                                        </div>
                                        <div className="aappoint-xl-hidden">
                                            {
                                                (record.email && record.email.length > 0 && record.email.length > 0) ?
                                                    <small><a href={`mailto:${record.email}`} className="aappoint-secondary-color"><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{record.email}</span></a></small> :
                                                    <small><div className="aappoint-secondary-color"><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div></small>
                                            }
                                        </div>
                                        <div className="aappoint-xl-hidden">
                                            {
                                                (record.phone_no && record.phone_no.length > 0 && record.phone_no.length > 0) ?
                                                    <small><a href={`tel:${record.phone_no}`} className="aappoint-secondary-color"><PhoneOutlined size={24} />&nbsp;{record.phone_no}</a></small> :
                                                    <small><div className="aappoint-secondary-color"><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div></small>
                                            }
                                        </div>
                                    </div>

                                )
                        }
                    </div>
                )
            },
            ...getColumnSearchProps()
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            responsive: ['xl'],
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            render: (text, record) => {
                return (
                    (text !== null && text !== undefined) ?
                        (record.role) ? <a href={`mailto:${text}`}>{text}</a> : <a className="aappoint-secondary-color" href={`mailto:${text}`}>{text}</a> :
                        <div className="aappoint-secondary-color">N/A</div>
                )
            },
            ...getColumnSearchProps()
        },
        {
            title: 'Phone',
            dataIndex: 'phone_no',
            key: 'phone_no',
            responsive: ['xl'],
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            render: (text, record) => {
                return (
                    (text !== null && text !== undefined && text.length > 0) ?
                        (record.role) ? <a href={`tel:${text}`}>{text}</a> : <a className="aappoint-secondary-color" href={`tel:${text}`}>{text}</a> :
                        <div className="aappoint-secondary-color">N/A</div>
                )
            },
            ...getColumnSearchProps()
        },
        {
            title: 'Role',
            key: 'role',
            dataIndex: 'role',
            align: 'center',
            responsive: ['sm'],
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            filters: [
                {
                    text: 'admin',
                    value: 'admin'
                },
                {
                    text: 'finance',
                    value: 'finance'
                },
                {
                    text: 'user',
                    value: 'user'
                },
            ],
            render: (text, record) => {
                return (
                    <>
                        {
                            (record.role) ?
                                (
                                    <div>{text}</div>
                                ) :
                                (
                                    <div className="aappoint-secondary-color">N/A</div>
                                )
                        }
                    </>
                )
            },
        },
        {
            title: "Actions",
            key: 'action',
            align: 'right',
            render: (text, record) => {
                return (
                    <div style={{ display: "inline-flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                        <div style={{ paddingRight: "10px" }}>
                            <Tooltip title="Delete">
                                <Popconfirm
                                    title={`Are you sure to delete this user?`}
                                    placement="left"
                                    icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                                    okText="Yes"
                                    cancelText="No"
                                    okType="danger"
                                    disabled={(currentUser && currentUser.uid === record.id)}
                                    onConfirm={() => {
                                        onDeleteUser(record.id)
                                    }}
                                >
                                    <Button type="danger" ghost shape="circle" icon={<DeleteOutlined />} disabled={(currentUser && currentUser.uid === record.id)} />
                                </Popconfirm>
                            </Tooltip>
                        </div>
                        <div>
                            <Tooltip title="Edit">
                                <Button type="primary" ghost shape="circle" icon={<EditOutlined />} onClick={() => {
                                    onEditUser(record)
                                }} />
                            </Tooltip>
                        </div>
                    </div>
                )
            },
        }
    ]
}

const Users = () => {
    const [currentUser, setCurrentUser] = useState(null)
    const [usersData, setUsersData] = useState({ loading: true, data: null });
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [editingUser, setEditingUser] = useState(null);
    const [showAddNewUser, setShowAddNewUser] = useState(false);
    const [orderBy, setOrderBy] = useState('created_at')
    const [filter, setFilter] = useState(null)

    const fetchUsers = useCallback(async () => {
        if (currentUser) {
            console.log("FETCH USERS")
            setUsersData({ loading: true, data: null })
            try {
                const token = await currentUser.getIdToken()
                const resp = await listUsers({ token, page, pageSize, orderBy, filter })
                if (resp) {
                    setUsersData({ loading: false, data: resp.data })
                } else {
                    setUsersData({ loading: false, data: { users: [], total: 0 } })
                }
            } catch (err) {
                setUsersData({ loading: false, data: { users: [], total: 0 } })
                notifyError({ description: "Fail to fetch users from the server" })
            }
        }
    }, [currentUser, page, pageSize, orderBy, filter])

    useFirebase(firebase => {
        if (!currentUser) {
            firebase.auth().onAuthStateChanged(function (user) {
                setCurrentUser(user)
            });
            return
        }
        fetchUsers()
    }, [fetchUsers])

    const onAddUser = () => {
        setShowAddNewUser(true)
    }

    const onEditUser = async (user) => {
        setEditingUser(user)
    }

    const onDeleteUser = async (uid) => {
        if (currentUser) {
            try {
                const token = await currentUser.getIdToken()
                await deleteUser({ token, uid })
                notifySuccess({ description: "The user has been deleted successfuly" })
                fetchUsers()
            } catch (err) {
                notifyError({ description: "Fail to delete the user" })
            }
        }
    }

    const onTableChange = (pagination, filters, sorter, { currentDataSource, action }) => {
        if (pagination) {
            if (pagination.pageSize !== pageSize) {
                setPageSize(pagination.pageSize)
            }
            if (pagination.current !== page) {
                setPage(pagination.current)
            }
        }
        if (sorter) {
            const { columnKey, order } = sorter;
            if (order && columnKey) {
                const direction = (order === 'descend') ? 'DESC' : ''
                const key = (columnKey === 'name') ? 'first_name' : columnKey
                if (direction) {
                    setOrderBy(key + " " + direction)
                } else {
                    setOrderBy(key)
                }
            } else {
                setOrderBy('created_at')
            }
        }
        if (filters) {
            setFilter(filters)
        }
    }

    // if (!usersData.data && currentUser) {
    //     fetchUsers()
    // }

    return (
        <>
            <PageHeader
                title="Users"
                subTitle="The backoffice users"
                extra={[
                    <Button key="1" type="primary" icon={<UserAddOutlined />} onClick={onAddUser} >New User</Button>,
                ]}
            />
            <div className="aappoint-page-container">
                <Table
                    dataSource={usersData.data ? usersData.data.users : []}
                    loading={usersData.loading}
                    columns={columns({ currentUser, onAddUser, onEditUser, onDeleteUser })}
                    rowKey="id"
                    onChange={onTableChange}
                    pagination={{
                        size: "small",
                        total: (usersData.data) ? usersData.data.total : 0,
                        current: page,
                        pageSize: pageSize,
                        showLessItems: true,
                        showSizeChanger: true,
                    }}
                />
            </div>

            <UserModal
                currentUser={currentUser}
                data={editingUser}
                title="Edit User"
                visible={!!editingUser}
                destroyOnClose={true}
                onOk={() => {
                    fetchUsers()
                    setEditingUser(null)
                }}
                onCancel={() => {
                    setEditingUser(null)
                }}
            />

            <NewUserModal
                currentUser={currentUser}
                title="Add New User"
                visible={showAddNewUser}
                destroyOnClose={true}
                onOk={() => {
                    fetchUsers()
                    setShowAddNewUser(false)
                }}
                onCancel={() => {
                    setShowAddNewUser(false)
                }}
            />
        </>
    )
}

export default Users;