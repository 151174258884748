import React, { useEffect, useState } from "react"
import { Row, Col, Typography, Radio, Statistic, Tabs, DatePicker } from 'antd'
import { ShopOutlined, ExclamationCircleOutlined, CloseCircleOutlined, PauseCircleOutlined, PlayCircleOutlined, SmileOutlined } from '@ant-design/icons'
import { Bar, Doughnut } from 'react-chartjs-2';
import { getShopSummary, getNewShopsData, getUserSummary, getNewUsersData } from '../services/api'
import { notifyError } from './notification'
import { useFirebase } from './firebase/context'
import PageHeader from './page-header'
import moment from 'moment'

const { Title } = Typography;
const { RangePicker } = DatePicker;

const doughnutOptions = {
    plugins: {
        legend: {
            display: true,
            position: 'bottom'
        }
    },
    maintainAspectRatio: false,
}

const chartOptions = {
    plugins: {
        legend: {
            display: true,
            position: 'bottom'
        }
    },
    scales: {
        y: {
            beginAtZero: true
        }
    }
}


const Dashboard = () => {
    const [currentUser, setCurrentUser] = useState(null)
    const [tabsKey, setTabsKey] = useState("1")

    const endDay = moment().startOf('day')
    const startDay = moment().startOf('day').subtract(6, 'days')
    const endMonth = moment().startOf('month')
    const startMonth = moment().startOf('month').subtract(5, 'months')
    const endYear = moment().startOf('year')
    const startYear = moment().startOf('year').subtract(4, 'years')

    const [shopSummary, setShopSummary] = useState(null)
    const [newShopData, setNewShopData] = useState(null)
    const [shopModeOptions, setShopModeOptions] = useState("daily")
    const [shopDayIntervalOptions, setShopDayIntervalOptions] = useState([startDay, endDay])
    const [shopMonthIntervalOptions, setShopMonthIntervalOptions] = useState([startMonth, endMonth])
    const [shopYearIntervalOptions, setShopYearIntervalOptions] = useState([startYear, endYear])

    const [userSummary, setUserSummary] = useState(null)
    const [newUserData, setNewUserData] = useState(null)
    const [userModeOptions, setUserModeOptions] = useState("daily")
    const [userDayIntervalOptions, setUserDayIntervalOptions] = useState([startDay, endDay])
    const [userMonthIntervalOptions, setUserMonthIntervalOptions] = useState([startMonth, endMonth])
    const [userYearIntervalOptions, setUserYearIntervalOptions] = useState([startYear, endYear])

    const shopChartData = () => {
        if (newShopData) {
            return {
                labels: newShopData.labels,
                datasets: [{
                    label: 'New shops',
                    data: newShopData.data,
                    borderWidth: 1,
                    backgroundColor: ['rgba(0,193,173,0.5)'],
                    borderColor: ['rgb(0,193,173)']
                }],
            }
        }
        return {}
    }

    const userChartData = () => {
        if (newUserData) {
            return {
                labels: newUserData.labels,
                datasets: [{
                    label: 'New users',
                    data: newUserData.data,
                    borderWidth: 1,
                    backgroundColor: ['rgba(4, 144, 255,0.5)'],
                    borderColor: ['rgb(4, 144, 255)']
                }],
            }
        }
        return {}
    }

    useFirebase(firebase => {
        if (!currentUser) {
            firebase.auth().onAuthStateChanged(function (user) {
                setCurrentUser(user)
            });
            return
        }
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            if (currentUser) {
                try {
                    const token = await currentUser.getIdToken()
                    if (tabsKey === "1") {
                        const summaryResp = await getShopSummary({ token })
                        setShopSummary(summaryResp.data)
                        const from = (shopModeOptions === 'monthly') ? shopMonthIntervalOptions[0] : (shopModeOptions === 'yearly') ? shopYearIntervalOptions[0] : shopDayIntervalOptions[0]
                        const to = (shopModeOptions === 'monthly') ? shopMonthIntervalOptions[1] : (shopModeOptions === 'yearly') ? shopYearIntervalOptions[1] : shopDayIntervalOptions[1]
                        const newShopsResp = await getNewShopsData({ token, mode: shopModeOptions, from: from.format(), to: to.format() })
                        setNewShopData(newShopsResp.data)
                    } else if (tabsKey === "2") {
                        const summaryResp = await getUserSummary({ token })
                        setUserSummary(summaryResp.data)
                        const from = (userModeOptions === 'monthly') ? userMonthIntervalOptions[0] : (userModeOptions === 'yearly') ? userYearIntervalOptions[0] : userDayIntervalOptions[0]
                        const to = (userModeOptions === 'monthly') ? userMonthIntervalOptions[1] : (userModeOptions === 'yearly') ? userYearIntervalOptions[1] : userDayIntervalOptions[1]
                        const newUsersResp = await getNewUsersData({ token, mode: userModeOptions, from: from.format(), to: to.format() })
                        setNewUserData(newUsersResp.data)
                    }
                } catch (err) {
                    const { message } = (err.response && err.response.data) ? err.response.data : {}
                    if (message === 'Duration limit exceeded') {
                        notifyError({ description: "The selected interval is exceeded the limit. The API allows 31 days or 24 months or 20 years for the interval" })
                    } else {
                        notifyError({ description: "Fail to fetch the data from the server" })
                    }
                }
            }
        }
        fetchData();
    }, [
        currentUser,
        tabsKey,
        shopModeOptions,
        shopDayIntervalOptions,
        shopMonthIntervalOptions,
        shopYearIntervalOptions,
        userModeOptions,
        userDayIntervalOptions,
        userMonthIntervalOptions,
        userYearIntervalOptions,
    ])

    const onTabsChange = (activeKey) => {
        setTabsKey(activeKey)
    }

    const onShopDataModeChange = (e) => {
        setShopModeOptions(e.target.value)
    };

    const onShopDayPickerChange = (dates) => {
        setShopDayIntervalOptions(dates)
    }

    const onShopMonthPickerChange = (dates) => {
        setShopMonthIntervalOptions(dates)
    }

    const onShopYearPickerChange = (dates) => {
        setShopYearIntervalOptions(dates)
    }

    const onUserDataModeChange = (e) => {
        setUserModeOptions(e.target.value)
    }

    const onUserDayPickerChange = (dates) => {
        setUserDayIntervalOptions(dates)
    }

    const onUserMonthPickerChange = (dates) => {
        setUserMonthIntervalOptions(dates)
    }

    const onUserYearPickerChange = (dates) => {
        setUserYearIntervalOptions(dates)
    }


    const shopDoughnutData = () => {
        if (shopSummary) {
            return {
                datasets: [{
                    label: '',
                    data: shopSummary.data,
                    backgroundColor: ['rgba(0,193,173,0.5)', 'rgba(245,34,45,0.5)', 'rgba(24, 144, 255,0.5)', 'rgba(250, 173, 20,0.5)'],
                    borderColor: ['rgb(0,193,173)', 'rgb(245,34,45)', 'rgb(24, 144, 255)', 'rgb(250, 173, 20)'],
                }],
                labels: shopSummary.labels
            }
        }
        return {}
    }

    return (
        <>
            <PageHeader
                title="Dashboard"
                subTitle=""
            />
            <div className="aappoint-page-container" >
                <Tabs defaultActiveKey="1" onChange={onTabsChange}>
                    <Tabs.TabPane
                        tab={
                            <span>
                                <ShopOutlined />
                                Shops
                            </span>
                        }
                        key="1"
                    >
                        {
                            shopSummary &&
                            <>
                                <Row gutter={[16, 16]} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                    <Col xs={24} sm={24} md={15} lg={15} xl={12} style={{ paddingBottom: '20px' }}>
                                        <Row>
                                            <Col span={8}>
                                                <Statistic title="Total" value={shopSummary.total} prefix={<span className="aappoint-secondary-color"><ShopOutlined /></span>} />
                                            </Col>
                                            <Col span={8}>
                                                <Statistic
                                                    title="Operate"
                                                    value={shopSummary.data[0]}
                                                    prefix={<PlayCircleOutlined style={{ color: "rgb(0,193,173)" }} />}
                                                    suffix={
                                                        <span className="aappoint-secondary-color" style={{ fontSize: "x-small" }}>
                                                            {(shopSummary.total) ? `(${(100.0 * shopSummary.data[0] / shopSummary.total).toFixed(2)}%)` : ''}
                                                        </span>
                                                    }
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <Statistic
                                                    title="Discontinue"
                                                    value={shopSummary.data[1]}
                                                    prefix={<CloseCircleOutlined style={{ color: "rgb(245,34,45)" }} />}
                                                    suffix={
                                                        <span className="aappoint-secondary-color" style={{ fontSize: "x-small" }}>
                                                            {(shopSummary.total) ? `(${(100.0 * shopSummary.data[1] / shopSummary.total).toFixed(2)}%)` : ''}
                                                        </span>
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ paddingTop: "20px" }}>
                                            <Col span={8}></Col>
                                            <Col span={8}>
                                                <Statistic
                                                    title="Pending"
                                                    value={shopSummary.data[2]}
                                                    prefix={<PauseCircleOutlined style={{ color: "rgb(24, 144, 255)" }} />}
                                                    suffix={
                                                        <span className="aappoint-secondary-color" style={{ fontSize: "x-small" }}>
                                                            {(shopSummary.total) ? `(${(100.0 * shopSummary.data[2] / shopSummary.total).toFixed(2)}%)` : ''}
                                                        </span>
                                                    }
                                                />
                                            </Col>
                                            <Col span={8}>
                                                <Statistic
                                                    title="Request"
                                                    value={shopSummary.data[3]}
                                                    prefix={<ExclamationCircleOutlined style={{ color: "rgb(250, 173, 20)" }} />}
                                                    suffix={
                                                        <span className="aappoint-secondary-color" style={{ fontSize: "x-small" }}>
                                                            {(shopSummary.total) ? `(${(100.0 * shopSummary.data[3] / shopSummary.total).toFixed(2)}%)` : ''}
                                                        </span>
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={9} lg={9} xl={12}>
                                        <Doughnut data={shopDoughnutData} options={doughnutOptions} width={400} height={250} />
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "40px" }}>
                                    <Col xs={24} sm={12} md={14} lg={16} xl={18}>
                                        <Title level={5}>New shops</Title>
                                    </Col>
                                    <Col xs={24} sm={12} md={10} lg={8} xl={6}>
                                        <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                            <div style={{ float: "right", width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingBottom: "16px" }}>
                                                <Radio.Group onChange={onShopDataModeChange} defaultValue="daily" >
                                                    <Radio.Button value="daily">Daily</Radio.Button>
                                                    <Radio.Button value="monthly">Monthly</Radio.Button>
                                                    <Radio.Button value="yearly">Yearly</Radio.Button>
                                                </Radio.Group>
                                            </div>
                                            <div style={{ float: "right", width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingBottom: "16px" }}>
                                                {
                                                    (shopModeOptions === 'daily') ?
                                                        <RangePicker
                                                            value={shopDayIntervalOptions}
                                                            onChange={onShopDayPickerChange}
                                                            ranges={{
                                                                'This Week': [moment().startOf('week'), moment().endOf('week')],
                                                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                                                'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                                            }}
                                                        /> :
                                                        (shopModeOptions === 'monthly') ?
                                                            <RangePicker
                                                                value={shopMonthIntervalOptions}
                                                                onChange={onShopMonthPickerChange}
                                                                ranges={{
                                                                    'This Year': [moment().startOf('year'), moment().endOf('year')],
                                                                    '6 Months': [moment().startOf('month').subtract(5, 'months'), moment().startOf('month')],
                                                                    '12 Months': [moment().startOf('month').subtract(11, 'months'), moment().startOf('month')],
                                                                }}
                                                                picker="month"
                                                            /> :
                                                            (shopModeOptions === 'yearly') ?
                                                                <RangePicker
                                                                    value={shopYearIntervalOptions}
                                                                    onChange={onShopYearPickerChange}
                                                                    picker="year"
                                                                /> : <></>
                                                }
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ paddingTop: "20px" }}>
                                    <Col span={24}>
                                        <Bar data={shopChartData()} options={chartOptions} />
                                    </Col>
                                </Row>
                            </>
                        }
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={
                            <span>
                                <SmileOutlined />
                                Customers
                            </span>
                        }
                        key="2"
                    >
                        {
                            userSummary &&
                            <Row gutter={[16, 16]} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                                <Col span={24} style={{ paddingBottom: '20px' }}>
                                    <Statistic title="Total" value={userSummary.total} prefix={<span className="aappoint-secondary-color"><SmileOutlined /></span>} />
                                </Col>
                            </Row>
                        }
                        <Row style={{ paddingTop: "40px" }}>
                            <Col xs={24} sm={12} md={14} lg={16} xl={18}>
                                <Title level={5}>New users</Title>
                            </Col>
                            <Col xs={24} sm={12} md={10} lg={8} xl={6}>
                                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                    <div style={{ float: "right", width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingBottom: "16px" }}>
                                        <Radio.Group onChange={onUserDataModeChange} defaultValue="daily" >
                                            <Radio.Button value="daily">Daily</Radio.Button>
                                            <Radio.Button value="monthly">Monthly</Radio.Button>
                                            <Radio.Button value="yearly">Yearly</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                    <div style={{ float: "right", width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingBottom: "16px" }}>
                                        {
                                            (userModeOptions === 'daily') ?
                                                <RangePicker
                                                    value={userDayIntervalOptions}
                                                    onChange={onUserDayPickerChange}
                                                    ranges={{
                                                        'This Week': [moment().startOf('week'), moment().endOf('week')],
                                                        'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                                                        'This Month': [moment().startOf('month'), moment().endOf('month')],
                                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                                    }}
                                                /> :
                                                (userModeOptions === 'monthly') ?
                                                    <RangePicker
                                                        value={userMonthIntervalOptions}
                                                        onChange={onUserMonthPickerChange}
                                                        ranges={{
                                                            'This Year': [moment().startOf('year'), moment().endOf('year')],
                                                            '6 Months': [moment().startOf('month').subtract(5, 'months'), moment().startOf('month')],
                                                            '12 Months': [moment().startOf('month').subtract(11, 'months'), moment().startOf('month')],
                                                        }}
                                                        picker="month"
                                                    /> :
                                                    (userModeOptions === 'yearly') ?
                                                        <RangePicker
                                                            value={userYearIntervalOptions}
                                                            onChange={onUserYearPickerChange}
                                                            picker="year"
                                                        /> : <></>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "20px" }}>
                            <Col span={24}>
                                <Bar data={userChartData()} options={chartOptions} />
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </>
    )
}
export default Dashboard