import React from 'react'
import { Typography } from 'antd'

const { Text } = Typography;

const ShopStatus = ({ status }) => (
    (status === 'operate') ?
        <span><Text type="success">{status}</Text></span> :
        (status === 'pending') ?
            <span><Text type="secondary">{status}</Text></span> :
            (status === 'request') ?
                <Text type="warning">{status}</Text> :
                (status === 'discontinue') ?
                    <span><Text type="danger">{status}</Text></span> :
                    <span><Text disabled>{status}</Text></span>
)

export default ShopStatus;