import React, { useState } from 'react'
import { Modal, Form, Select, Avatar, Button } from 'antd'
import { UserOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons'
import Roles from '../roles'
import { setUserRole } from '../../services/api'
import { notifySuccess, notifyError } from '../notification'

const RolesOptionList = Roles.map((type, idx) => <Select.Option key={`RoleKey${idx}`} value={type.value}>{type.label}</Select.Option>)

const UserModal = ({ currentUser, data, onOk, onCancel, ...rest }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onFinish = async (values) => {
        if (currentUser) {
            setLoading(true)
            try {
                const role = values.role
                const token = await currentUser.getIdToken()
                const uid = (data) ? data.id : ""
                await setUserRole({ token, uid, role })
                notifySuccess({ description: "The user's role has been updated successfuly" })
                setLoading(false)
                if (onOk) {
                    onOk();
                }
            } catch (err) {
                setLoading(false)
                if (err) {
                    notifyError({ description: "Fail to update the user's role" })
                }
            }
        }
    }

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    if (data) {
        form.setFieldsValue({ role: (data.role && data.role.length > 0) ? data.role : "None" });
    }

    return (
        <Modal
            onOk={form.submit}
            onCancel={handleOnCancel}
            footer={[
                <Button key="cancel" onClick={handleOnCancel}>
                    Cancel
                </Button>,
                <Button key="ok" type="primary" loading={loading} onClick={form.submit}>
                    OK
                </Button>,
            ]}
            {...rest}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                {
                    data &&
                    (
                        <>
                            <div style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column', display: 'flex' }}>
                                <div style={{ paddingBottom: "16px" }}>
                                    {
                                        data.picture ?
                                            <Avatar size={96} src={data.picture} className="aappoint-avatar-shadow" /> :
                                            <Avatar size={96} icon={<UserOutlined />} className="aappoint-avatar-shadow" />
                                    }
                                </div>
                                <div style={{ paddingBottom: "16px" }}>
                                    {
                                        ((!data.first_name || data.first_name.length === 0) &&
                                            (!data.last_name || data.last_name.length === 0)) ?
                                            <div className="aappoint-secondary-color">N/A</div> :
                                            (data.first_name && data.first_name.length > 0) ?
                                                (data.last_name && data.last_name.length > 0) ?
                                                    <div>{`${data.first_name} ${data.last_name}`}</div> :
                                                    <div>{`${data.first_name}`}</div> :
                                                <div>{`${data.last_name}`}</div>
                                    }
                                </div>
                                <div style={{ paddingBottom: "16px" }}>
                                    {
                                        data.email && data.email.length > 0 ?
                                            <a href={`mailto:${data.email}`}><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{data.email}</span></a> :
                                            <div className="aappoint-secondary-color"><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                                    }
                                </div>
                                <div style={{ paddingBottom: "16px" }}>
                                    {
                                        data.phone_no && data.phone_no.length > 0 ?
                                            <a href={`tel:${data.phone_no}`}><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{data.phone_no}</span></a> :
                                            <div className="aappoint-secondary-color"><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                                    }
                                </div>
                            </div>
                            <Form.Item
                                name="role"
                                label="Role"
                            >
                                <Select
                                    placeholder="Select a role"
                                >
                                    {RolesOptionList}
                                </Select>
                            </Form.Item>
                        </>
                    )
                }
            </Form>
        </Modal>
    )
}

export default UserModal;