import React, { useState } from 'react'
import { Modal, Form, Input, Typography, Avatar, Button } from 'antd'
import { ControlOutlined } from '@ant-design/icons'
import { setConfig } from '../../services/api'
import { notifySuccess, notifyError } from '../notification'

const { Text } = Typography;

const ConfigModal = ({ currentUser, config, onOk, onCancel, ...rest }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    if (config) {
        form.setFieldsValue({ value: config.value });
    }

    const onFinish = async (values) => {
        if (currentUser && config) {
            setLoading(true)
            try {
                const { key, label } = config
                const value = values.value
                const token = await currentUser.getIdToken()
                await setConfig({ token, key, value, label })
                notifySuccess({ description: "Configuration has been updated successfully" })
                setLoading(false)
                if (onOk) {
                    onOk();
                }
            } catch (err) {
                setLoading(false)
                notifyError({ description: "Fail to update configuration" })
            }
        }
    }

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    return (
        <Modal
            onOk={form.submit}
            onCancel={handleOnCancel}
            footer={[
                <Button key="cancel" onClick={handleOnCancel}>
                    Cancel
                </Button>,
                <Button key="ok" type="primary" loading={loading} onClick={form.submit}>
                    OK
                </Button>,
            ]}
            {...rest}
        >
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <div style={{ paddingBottom: "16px" }}>
                    <Avatar size={96} icon={<ControlOutlined />} className="aappoint-avatar-shadow .aappoint-avatar-default" />
                </div>
                <div style={{ paddingBottom: "8px" }}>
                    <Text>{(config) ? config.label : "N/A"}</Text>
                </div>
                <div style={{ paddingBottom: "8px" }}>
                    <Text disabled><small>{`key: ${(config) ? config.key : "N/A"}`}</small></Text>
                </div>
            </div>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="value"
                    label="Value"
                    rules={[{ required: true, message: 'Please input the value of the configuration!' }]}
                >
                    <Input placeholder="Enter the value of the configuration" />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ConfigModal;