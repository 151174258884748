import React, { useContext } from "react"
import { navigate } from "gatsby"
import FirebaseContext from './firebase/context'

const PrivateRoute = ({ component: Component, location, path, ...rest }) => {
    const firebase = useContext(FirebaseContext)
    if (!firebase) {
        return null
    }
    const user = firebase.auth().currentUser;
    if (!user && location.pathname !== `/`) {
        if (path !== "/") {
            navigate(`/?redirect=${encodeURIComponent(location.pathname)}`)
        } else {
            navigate("/")
        }
        return null
    }
    return <Component {...rest} />
}
export default PrivateRoute