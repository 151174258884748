import React, { useState, useCallback } from 'react';
import PageHeader from './page-header'
import { Avatar, Typography, Table, Button } from 'antd'
import { ShopOutlined, FileAddOutlined } from '@ant-design/icons'
import { Link } from 'gatsby'
import { getColumnSearchProps, getColumnMonthPickerProps } from './column-search'
import moment from 'moment'
import { listInvoices } from '../services/api'
import { notifyError } from './notification'
import { useFirebase } from './firebase/context'
import NewInvoiceModal from './modals/new-invoice-modal'

const { Text } = Typography

export const invoicePrefix = 'AAPINV'

const zeroPad = (num, places) => String(num).padStart(places, '0')

export const invoiceColumns = [
    {
        title: 'Invoice#',
        key: 'id',
        dataIndex: 'id',
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        render: (text, record) => {
            return (
                <>
                    <div className="aappoint-xs-hidden aappoint-md-block">
                        <span className="aappoint-secondary-color">{invoicePrefix}</span>
                        <Link to={`/app/invoices/${record.id}`}>{zeroPad(record.id, 9)}</Link>
                    </div>
                    <div className="aappoint-md-hidden" style={{ display: 'flex' }}>
                        <small>
                            <div className="aappoint-secondary-color" style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>{invoicePrefix}</div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <Link to={`/app/invoices/${record.id}`}>{zeroPad(record.id, 9)}</Link>
                            </div>
                        </small>
                    </div>
                </>
            )
        },
        ...getColumnSearchProps(),
    },
    {
        title: 'Shop',
        key: 'shop',
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        render: (text, record) => {
            const { shop } = record
            const avatar = (shop && shop.logo) ?
                <Avatar src={shop.logo} size={48} /> :
                <Avatar icon={<ShopOutlined />} size={48} />
            const title = (record) ? (shop.name_en) ? shop.name_en : (shop.name_th) ? shop.name_th : "N/A" : "N/A"
            const subTitle = (record && shop.name_en && shop.name_th) ? shop.name_th : ""
            return (
                <div style={{ display: 'inline-flex', justifyContent: 'justify-start', alignItems: 'center' }}>
                    {avatar}
                    <div style={{ paddingLeft: '16px', flexDirection: 'column', display: 'flex' }}>
                        {title}
                        <div className="aappoint-secondary-color"><small>{subTitle}</small></div>
                        <div className="aappoint-secondary-color"><small>{`ID: ${subTitle}`}</small></div>
                    </div>
                </div>
            )
        },
        ...getColumnSearchProps(),
    },
    {
        title: 'Service Month',
        dataIndex: 'service_month',
        key: 'service_month',
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        responsive: ['md'],
        render: (text, record) => {
            return (
                (text) &&
                <div>{moment(text, "YYYYMM").format("YYYY/MM")}</div>
            )
        },
        ...getColumnMonthPickerProps(),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        filters: [
            {
                text: 'created',
                value: 'created'
            },
            {
                text: 'reviewed',
                value: 'reviewed'
            },
            {
                text: 'approved',
                value: 'approved'
            },
            {
                text: 'paid',
                value: 'paid'
            },
            {
                text: 'voided',
                value: 'voided'
            },
        ],
        render: (text, record) => {
            return (
                (text === 'reviewed') ?
                    <span className="aappoint-warning-color">{text}</span> :
                    (text === 'approved') ?
                        <span className="aappoint-secondary-color">{text}</span> :
                        (text === 'paid') ?
                            <span className="aappoint-success-color">{text}</span> :
                            (text === 'voided') ?
                                <span className="aappoint-danger-color">{text}</span> :
                                <span className="aappoint-secondary-color">{text}</span>
            )
        }
    }
]

const Invoices = () => {
    const [currentUser, setCurrentUser] = useState(null)
    const [invoicesData, setInvoicesData] = useState({
        loading: false,
        data: null,
    })
    const [invoicePage, setInvoicePage] = useState(1)
    const [invoicePageSize, setInvoicePageSize] = useState(20)
    const [invoiceOrderBy, setInvoiceOrderBy] = useState("bo_invoices.created_at DESC")
    const [invoiceFilter, setInvoiceFilter] = useState(null)
    const [showNewInvoiceModal, setShowNewInvoiceModal] = useState(false)

    const fetchInvoices = useCallback(async () => {
        console.log("FETCH INVOCES")
        if (currentUser) {
            try {
                const token = await currentUser.getIdToken()
                setInvoicesData({ loading: true, data: null })
                const resp = await listInvoices({
                    token,
                    page: invoicePage,
                    pageSize: invoicePageSize,
                    orderBy: invoiceOrderBy,
                    filter: invoiceFilter,
                })
                if (resp) {
                    setInvoicesData({ loading: false, data: resp.data })
                } else {
                    setInvoicesData({ loading: false, data: null })
                }
            } catch (err) {
                setInvoicesData({ loading: false, data: null })
                notifyError({ description: "Fail to fetch shop's invoices from the server" })
            }
        }
    }, [currentUser, invoicePage, invoicePageSize, invoiceFilter, invoiceOrderBy])

    useFirebase(firebase => {
        if (!currentUser) {
            firebase.auth().onAuthStateChanged(function (user) {
                setCurrentUser(user)
            });
            return
        }
        fetchInvoices()
    }, [fetchInvoices])

    const onInvoiceTableChange = async (pagination, filters, sorter) => {
        if (pagination) {
            if (pagination.pageSize !== invoicePageSize) {
                setInvoicePageSize(pagination.pageSize)
            }
            if (pagination.current !== invoicePage) {
                setInvoicePage(pagination.current)
            }
        }
        if (sorter) {
            const { columnKey, order } = sorter;
            if (columnKey) {
                const direction = (order === 'ascend') ? 'ASC' : (order === 'descend') ? 'DESC' : ''
                const key = (columnKey === 'shop') ? 'shop.name_en' : `bo_invoices.${columnKey}`
                const v = key + " " + direction
                setInvoiceOrderBy(v)
            } else {
                setInvoiceOrderBy("bo_invoices.created_at DESC")
            }
        }
        if (filters) {
            const { id, service_month, ...rest } = filters
            const newFilter = { ...rest }
            if (id) {
                const invID = parseInt(`${id}`.replace(invoicePrefix, ""))
                if (invID) {
                    newFilter.id = invID
                }
            }
            if (service_month && service_month.length > 0) {
                newFilter.service_month = service_month[0].format("YYYYMM")
            }
            setInvoiceFilter(newFilter)
        }
    }

    const onAddInvoice = () => {
        setShowNewInvoiceModal(true)
    }

    return (
        <>
            <PageHeader
                title="Invoices"
                subTitle=""
                extra={[
                    <Button key="1" type="primary" icon={<FileAddOutlined />} onClick={onAddInvoice} >New Invoice</Button>,
                ]}
            />
            <div className="aappoint-page-container">
                <Table
                    dataSource={(invoicesData.data ? invoicesData.data.invoices : [])}
                    columns={invoiceColumns}
                    rowKey="id"
                    onChange={onInvoiceTableChange}
                    pagination={{
                        size: "small",
                        total: invoicesData.data ? invoicesData.data.total : 0,
                        current: invoicePage,
                        pageSize: invoicePageSize,
                        showLessItems: true,
                        showSizeChanger: true,
                    }}
                />
            </div>

            <NewInvoiceModal
                currentUser={currentUser}
                title="New Invoice"
                visible={showNewInvoiceModal}
                destroyOnClose={true}
                onOk={() => {
                    setShowNewInvoiceModal(false)
                    fetchInvoices()
                }}
                onCancel={() => {
                    setShowNewInvoiceModal(false)
                }}
            />
        </>
    )
}

export default Invoices