const Languages = [
    {
        value: 'en',
        label: 'English'
    },
    {
        value: 'th',
        label: 'Thai'
    }
]

export default Languages;