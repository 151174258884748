import React, { useState, useCallback } from 'react';
import PageHeader from './page-header'
import { Table, Tooltip, Button } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import { useFirebase } from './firebase/context'
import { notifyError } from './notification'
import { listConfigs } from '../services/api'
import ConfigModal from './modals/config-modal'

const columns = ({ onEditConfig }) => {
    return [
        {
            title: 'Property',
            dataIndex: 'label',
            key: 'label',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>{record.label}</div>
                        <div className="aappoint-secondary-color"><small>{`key: ${record.key}`}</small></div>
                    </div>
                )
            }
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
            sortDirections: ['ascend', 'descend'],
            sorter: true,
        },
        {
            title: 'Action',
            key: 'action',
            align: 'right',
            render: (text, record) => {
                return (
                    <div style={{ display: "inline-flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
                        <div>
                            <Tooltip title="Edit">
                                <Button type="primary" ghost shape="circle" icon={<EditOutlined />} onClick={() => {
                                    onEditConfig(record)
                                }} />
                            </Tooltip>
                        </div>
                    </div>
                )
            }
        },
    ]
}

const Settings = () => {
    const [currentUser, setCurrentUser] = useState(null)
    const [configsData, setConfigsData] = useState({ loading: true, data: null })
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [orderBy, setOrderBy] = useState("label")
    const [filter, setFilter] = useState(null)
    const [editingConfig, setEditingConfig] = useState(null)

    const fetchConfigs = useCallback(async () => {
        if (currentUser) {
            console.log("FETCH CONFIGS")
            setConfigsData({ loading: true })
            try {
                const token = await currentUser.getIdToken()
                const resp = await listConfigs({ token, page, pageSize, orderBy, filter })
                if (resp) {
                    setConfigsData({ loading: false, data: resp.data })
                } else {
                    setConfigsData({ loading: false, data: { configs: [], total: 0 } })
                }
            } catch (err) {
                setConfigsData({ loading: false, data: { configs: [], total: 0 } })
                notifyError({ description: "Fail to fetch configurations from the server" })
            }
        }
    }, [currentUser, page, pageSize, orderBy, filter])

    useFirebase(firebase => {
        if (!currentUser) {
            firebase.auth().onAuthStateChanged(function (user) {
                setCurrentUser(user);
            });
            return
        }
        fetchConfigs();
    }, [fetchConfigs])

    const onEditConfig = async (config) => {
        setEditingConfig(config)
    }

    const onTableChange = (pagination, filters, sorter, { currentDataSource, action }) => {
        // console.log("Pagination: ", pagination)
        // console.log("Filters: ", filters)
        if (pagination) {
            if (pagination.pageSize !== pageSize) {
                setPageSize(pagination.pageSize)
            }
            if (pagination.current !== page) {
                setPage(pagination.current)
            }
        }
        if (sorter) {
            const { columnKey, order } = sorter;
            if (columnKey) {
                const direction = (order === 'ascend') ? 'ASC' : (order === 'descend') ? 'DESC' : ''
                const key = columnKey
                const v = key + " " + direction
                setOrderBy(v)
            } else {
                setOrderBy("created_at")
            }
        }
        if (filters) {
            setFilter(filters)
        }
    }

    return (
        <>
            <PageHeader
                title="Settings"
                subTitle=""
            />
            <div className="aappoint-page-container">
                <Table
                    dataSource={(configsData.data ? configsData.data.configs : [])}
                    columns={columns({ onEditConfig })}
                    rowKey="id"
                    onChange={onTableChange}
                    pagination={{
                        size: "small",
                        total: configsData.data ? configsData.data.total : 0,
                        current: page,
                        pageSize: pageSize,
                        showLessItems: true,
                        showSizeChanger: true,
                    }}
                />
            </div>

            <ConfigModal
                config={editingConfig}
                currentUser={currentUser}
                title="Edit Configuration"
                visible={(editingConfig)}
                destroyOnClose={true}
                onOk={() => {
                    fetchConfigs()
                    setEditingConfig(null)
                }}
                onCancel={() => {
                    setEditingConfig(null)
                }}
            />
        </>
    )
}

export default Settings