import React, { useState, useCallback } from 'react';
import { Table, Avatar, Typography } from 'antd'
import { ShopOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { useFirebase } from './firebase/context'
import { notifyError } from './notification'
import { listShops } from '../services/api'
import { Link } from 'gatsby'
import PageHeader from './page-header'
import ShopStatus from './shop-status'
import { getColumnSearchProps } from './column-search'

const { Text } = Typography;

export const shopColumns = [
    {
        title: 'ID',
        width: '100px',
        dataIndex: 'id',
        key: 'id',
        responsive: ['sm'],
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        ...getColumnSearchProps(),
    },
    {
        title: 'Name',
        key: 'name',
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        render: (text, record) => {
            const avatar = (record.logo) ?
                <Avatar src={record.logo} size={48} /> :
                <Avatar icon={<ShopOutlined />} size={48} />
            const title = (record) ? (record.name_en) ? record.name_en : (record.name_th) ? record.name_th : "N/A" : "N/A"
            const subTitle = (record && record.name_en && record.name_th) ? record.name_th : ""
            return (
                <div style={{ display: 'inline-flex', justifyContent: 'justify-start', alignItems: 'center' }}>
                    <Link to={`/app/shops/${record.id}`}>{avatar}</Link>
                    <div style={{ paddingLeft: '16px', flexDirection: 'column', display: 'flex' }}>
                        <Link to={`/app/shops/${record.id}`}>{title}</Link>
                        <div style={{ paddingBottom: "4px", display: "flex", flexDirection: "column" }}>
                            <Text type="secondary">{subTitle}</Text>

                        </div>
                        <div className="aappoint-xl-hidden">
                            {
                                (record.email !== undefined && record.email !== null) ?
                                    <small><a href={`mailto:${record.email}`}><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{record.email}</span></a></small> :
                                    <small><div className="aappoint-secondary-color"><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div></small>
                            }
                        </div>
                        <div className="aappoint-xl-hidden">
                            {
                                (record.phone !== undefined && record.phone !== null) ?
                                    <small><a href={`tel:${record.phone}`}><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{record.phone}</span></a></small> :
                                    <small><div className="aappoint-secondary-color"><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div></small>
                            }
                        </div>
                        <div className="aappoint-md-hidden">
                            <small>
                                <div className="aappoint-secondary-color" style={{ direction: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <Text type="secondary" style={{ direction: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <span className="aappoint-sm-hidden" style={{ paddingRight: "4px" }}>ID:<span style={{ paddingLeft: "4px" }}>{record.id}</span></span>
                                        <span className="aappoint-sm-hidden" style={{ paddingRight: "4px" }}>|</span>
                                        <span className="aappoint-md-hidden" style={{ paddingRight: "4px" }}>{record.service_type}</span>
                                        <span className="aappoint-md-hidden" style={{ paddingRight: "4px" }}>|</span>
                                        <ShopStatus status={record.status} />
                                    </Text>
                                </div>
                            </small>
                        </div>
                    </div>
                </div>
            )
        },
        ...getColumnSearchProps(),
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        responsive: ['xl'],
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        render: (text, record) => {
            return (
                (text !== undefined && text !== null) ?
                    <a href={`mailto:${text}`}>{text}</a> :
                    <div className="aappoint-secondary-color">N/A</div>
            )
        },
        ...getColumnSearchProps(),
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
        responsive: ['xl'],
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        render: (text, record) => {
            return (
                (text !== undefined && text !== null) ?
                    <a href={`tel:${text}`}>{text}</a> :
                    <div className="aappoint-secondary-color">N/A</div>
            )
        },
        ...getColumnSearchProps(),
    },
    {
        title: 'Service Type',
        dataIndex: 'service_type',
        key: 'service_type',
        align: 'center',
        responsive: ['md'],
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        render: (text, record) => {
            return (
                <Text type="secondary">{text}</Text>
            )
        },
        ...getColumnSearchProps(),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        responsive: ['md'],
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        filters: [
            {
                text: 'discontinue',
                value: 'discontinue'
            },
            {
                text: 'operate',
                value: 'operate'
            },
            {
                text: 'pending',
                value: 'pending'
            },
            {
                text: 'request',
                value: 'request'
            },
        ],
        render: (text, record) => {
            return (
                <ShopStatus status={record.status} />
            )
        }
    },
]

const Shops = () => {
    const [currentUser, setCurrentUser] = useState(null)
    const [shopsData, setShopsData] = useState({ loading: true, data: null })
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [orderBy, setOrderBy] = useState("created_at DESC")
    const [filter, setFilter] = useState(null)

    const fetchShops = useCallback(async () => {
        if (currentUser) {
            console.log("FETCH SHOPS")
            setShopsData({ loading: true })
            try {
                const token = await currentUser.getIdToken()
                const resp = await listShops({ token, page, pageSize, orderBy, filter })
                if (resp) {
                    setShopsData({ loading: false, data: resp.data })
                } else {
                    setShopsData({ loading: false, data: { shops: [], total: 0 } })
                }
            } catch (err) {
                setShopsData({ loading: false, data: { shops: [], total: 0 } })
                notifyError({ description: "Fail to fetch shop owners from the server" })
            }
        }
    }, [currentUser, page, pageSize, orderBy, filter])

    useFirebase(firebase => {
        if (!currentUser) {
            firebase.auth().onAuthStateChanged(function (user) {
                setCurrentUser(user)
            });
            return
        }
        fetchShops();
    }, [fetchShops])


    const onTableChange = (pagination, filters, sorter, { currentDataSource, action }) => {
        if (pagination) {
            if (pagination.pageSize !== pageSize) {
                setPageSize(pagination.pageSize)
            }
            if (pagination.current !== page) {
                setPage(pagination.current)
            }
        }
        if (sorter) {
            const { columnKey, order } = sorter;
            if (order && columnKey) {
                const direction = (order === 'descend') ? 'DESC' : ''
                const key = (columnKey === 'name') ? 'name_en' : columnKey
                if (direction) {
                    setOrderBy(key + " " + direction)
                } else {
                    setOrderBy(key)
                }
            } else {
                setOrderBy("created_at DESC")
            }
        }
        if (filters) {
            setFilter(filters)
        }
    }

    return (
        <>
            <PageHeader
                title="Shops"
                subTitle=""
            />
            <div className="aappoint-page-container">
                <Table
                    dataSource={shopsData.data ? shopsData.data.shops : []}
                    columns={shopColumns}
                    rowKey="id"
                    onChange={onTableChange}
                    pagination={{
                        size: "small",
                        total: (shopsData.data) ? shopsData.data.total : 0,
                        current: page,
                        pageSize: pageSize,
                        showLessItems: true,
                        showSizeChanger: true,
                    }}
                />
            </div>
        </>
    )
}

export default Shops