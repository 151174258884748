import React, { useContext, useState } from 'react'
import { Modal, Form, Input, Avatar } from 'antd'
import { KeyOutlined } from '@ant-design/icons'
import { notifySuccess, notifyError } from '../notification'
import FirebaseContext from '../firebase/context'

const ChangePasswordModal = ({ onOk, onCancel, ...rest }) => {
    const firebase = useContext(FirebaseContext)
    const [form] = Form.useForm();
    const [confirmNewPwdError, setConfirmNewPwdError] = useState(null)

    const changePassword = async (currentPassword, newPassword) => {
        var user = firebase.auth().currentUser;
        var cred = firebase.auth.EmailAuthProvider.credential(
            user.email, currentPassword);
        user.reauthenticateWithCredential(cred)
            .then(() => {
                return user.updatePassword(newPassword)
            })
            .catch((error) => {
                return Promise.reject(error)
            });
    }

    const onFinish = async (values) => {
        try {
            await changePassword(values.password, values.new_password)
            notifySuccess({ description: "Your password has been changed successfuly" })
            if (onOk) {
                onOk();
            }
        } catch (err) {
            notifyError({ description: "Fail to change the password" })
        }
    }

    const handleOnCancel = () => {
        if (onCancel) {
            onCancel();
        }
    }

    const onCheckConfirmNewPassword = async () => {
        try {
            const newPassword = form.getFieldValue("new_password")
            const confirmNewPassword = form.getFieldValue("confirm_new_password")
            if (confirmNewPassword && confirmNewPassword.length > 0 && newPassword !== confirmNewPassword) {
                setConfirmNewPwdError({ message: "The new password and confirmation new password do not match." })
            } else {
                setConfirmNewPwdError(null)
            }
        } catch (errorInfo) {
            console.log("Failed: ", errorInfo)
        }
    }

    return (
        <Modal onOk={form.submit} onCancel={handleOnCancel} {...rest}>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "20px" }}>
                <Avatar size={96} icon={<KeyOutlined />} className="aappoint-avatar-shadow .aappoint-avatar-default" />
            </div>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    label="Current password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your current password!' }]}
                >
                    <Input.Password placeholder="Enter your current password" />
                </Form.Item>
                <Form.Item
                    label="New password"
                    name="new_password"
                    rules={[{ required: true, message: 'Please input your new password!' }]}
                >
                    <Input.Password placeholder="Enter your new password" />
                </Form.Item>
                <Form.Item
                    label="Confirm new password"
                    name="confirm_new_password"
                    rules={[{ required: true, message: 'Please input your confirm new password!' }]}
                    validateStatus={confirmNewPwdError ? "error" : null}
                    help={confirmNewPwdError ? confirmNewPwdError.message : null}
                >
                    <Input.Password placeholder="Please confirm the new password" onChange={onCheckConfirmNewPassword} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ChangePasswordModal;