import React, { useState, useCallback } from 'react';
import { Avatar, Table, Typography } from 'antd'
import { UserOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons'
import { useFirebase } from './firebase/context'
import { notifyError } from './notification'
import { listOwners } from '../services/api'
import { Link } from 'gatsby';
import PageHeader from './page-header'
import { getColumnSearchProps } from './column-search'

const { Text } = Typography;

export const getOwnerName = (owner) => {
    return (owner.first_name) ?
        (owner.last_name && owner.last_name.length > 0) ?
            `${owner.first_name} ${owner.last_name}` :
            `${owner.first_name}` :
        `${owner.last_name}`
}

const columns = [
    {
        title: 'Name',
        key: 'name',
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        render: (text, record) => {
            const avatar = (record.picture) ?
                <Avatar src={record.picture} size={48} /> :
                <Avatar icon={<UserOutlined />} size={48} />

            const name = getOwnerName(record)
            return (
                <div style={{ display: 'inline-flex', justifyContent: 'justify-start', alignItems: 'center' }}>
                    <Link to={`/app/owners/${record.id}`}>{avatar}</Link>
                    <div style={{ paddingLeft: '16px', flexDirection: 'column', display: 'flex' }}>
                        <Link to={`/app/owners/${record.id}`}>{name}</Link>
                        <div className="aappoint-md-hidden">
                            {
                                (record.email !== undefined && record.email !== null) ?
                                    <small><a href={`mailto:${record.email}`}><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{record.email}</span></a></small> :
                                    <small><div className="aappoint-secondary-color"><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div></small>
                            }
                        </div>
                        <div className="aappoint-md-hidden">
                            {
                                (record.phone_no !== undefined && record.phone_no !== null) ?
                                    <small><a href={`tel:${record.phone_no}`}><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{record.phone_no}</span></a></small> :
                                    <small><div className="aappoint-secondary-color"><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div></small>
                            }
                        </div>
                        <div>
                            <div className="aappoint-secondary-color"><small>ID:<span style={{ paddingLeft: "4px" }}>{record.id}</span></small></div>
                        </div>
                    </div>
                </div>
            )
        },
        ...getColumnSearchProps(),
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        responsive: ['md'],
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        render: (text, record) => {
            return (
                (text !== undefined && text !== null) ?
                    <a href={`mailto:${text}`}>{text}</a> :
                    <div className="aappoint-secondary-color">N/A</div>
            )
        },
        ...getColumnSearchProps(),
    },
    {
        title: 'Phone',
        dataIndex: 'phone_no',
        key: 'phone_no',
        responsive: ['md'],
        sortDirections: ['ascend', 'descend'],
        sorter: true,
        render: (text, record) => {
            return (
                (text !== undefined && text !== null) ?
                    <a href={`tel:${text}`}>{text}</a> :
                    <div className="aappoint-secondary-color">N/A</div>
            )
        },
        ...getColumnSearchProps(),
    },
]


const Owners = () => {
    const [currentUser, setCurrentUser] = useState(null)
    const [ownersData, setOwnersData] = useState({ loading: true, data: null })
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [orderBy, setOrderBy] = useState("created_at DESC")
    const [filter, setFilter] = useState(null)

    const fetchOwners = useCallback(async () => {
        if (currentUser) {
            console.log("FETCH OWNERS")
            setOwnersData({ loading: true })
            try {
                const token = await currentUser.getIdToken()
                const resp = await listOwners({ token, page, pageSize, orderBy, filter })
                if (resp) {
                    setOwnersData({ loading: false, data: resp.data })
                } else {
                    setOwnersData({ loading: false, data: { users: [], total: 0 } })
                }
            } catch (err) {
                setOwnersData({ loading: false, data: { users: [], total: 0 } })
                notifyError({ description: "Fail to fetch shop owners from the server" })
            }
        }
    }, [currentUser, page, pageSize, orderBy, filter])

    useFirebase(firebase => {
        if (!currentUser) {
            firebase.auth().onAuthStateChanged(function (user) {
                setCurrentUser(user);
            });
            return
        }
        fetchOwners();
    }, [fetchOwners])

    const onTableChange = (pagination, filters, sorter, { currentDataSource, action }) => {
        // console.log("Pagination: ", pagination)
        // console.log("Filters: ", filters)
        if (pagination) {
            if (pagination.pageSize !== pageSize) {
                setPageSize(pagination.pageSize)
            }
            if (pagination.current !== page) {
                setPage(pagination.current)
            }
        }
        if (sorter) {
            const { columnKey, order } = sorter;
            if (columnKey) {
                const direction = (order === 'ascend') ? 'ASC' : (order === 'descend') ? 'DESC' : ''
                const key = (columnKey === 'name') ? 'first_name' : columnKey
                const v = key + " " + direction
                setOrderBy(v)
            } else {
                setOrderBy("created_at DESC")
            }
        }
        if (filters) {
            setFilter(filters)
        }
    }

    return (
        <>
            <PageHeader
                title="Owners"
                subTitle="The shop owners"
            />
            <div className="aappoint-page-container">
                <Table
                    dataSource={(ownersData.data ? ownersData.data.users : [])}
                    columns={columns}
                    rowKey="id"
                    onChange={onTableChange}
                    pagination={{
                        size: "small",
                        total: ownersData.data ? ownersData.data.total : 0,
                        current: page,
                        pageSize: pageSize,
                        showLessItems: true,
                        showSizeChanger: true,
                    }}
                />
            </div>
        </>
    )
}

export default Owners