import React, { useState, useCallback } from "react"
import { Avatar, Button, Typography } from "antd"
import { UserOutlined, PhoneOutlined, MailOutlined, LockOutlined, SafetyOutlined, EditOutlined } from '@ant-design/icons'
import ChangePasswordModal from './modals/change-password-modal'
import ProfileModal from './modals/profile-modal'
import { useFirebase } from './firebase/context'
import { getProfile as apiGetProfile } from '../services/api'
import { notifyError } from './notification'
import PageHeader from './page-header'

const { Title } = Typography

const Profile = () => {
    const [storage, setStorage] = useState(null)
    const [currentUser, setCurrentUser] = useState(null)
    const [profile, setProfile] = useState(null)
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
    const [showEditProfileModal, setShowEditProfileModal] = useState(false)

    const fetchProfile = useCallback(async () => {
        if (currentUser) {
            console.log("FETCH PROFILE")
            try {
                const token = await currentUser.getIdToken()
                const resp = await apiGetProfile({ token })
                if (resp) {
                    setProfile(resp.data)
                }
            } catch (err) {
                notifyError({ description: "Fail to fetch your profile from the server" })
            }
        }
    }, [currentUser])

    useFirebase(firebase => {
        if (!currentUser) {
            firebase.auth().onAuthStateChanged(function (user) {
                setCurrentUser(user);
            });
            return
        }
        if (!storage) {
            setStorage(firebase.storage())
        }
        fetchProfile();
    }, [fetchProfile])

    return (
        <>
            <PageHeader
                title="Profile"
                subTitle="Your profile"
            />
            <div className="aappoint-page-container">
                {
                    profile &&
                    <>
                        <div className="aappoint-md-hidden" style={{ display: 'flex', flexDirection: "column", width: "100%" }}>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", paddingBottom: "16px" }}>
                                {
                                    profile.picture ?
                                        <Avatar size={96} src={profile.picture} className="aappoint-avatar-shadow" /> :
                                        <Avatar size={96} icon={<UserOutlined />} className="aappoint-avatar-shadow" />
                                }
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", paddingBottom: "8px" }}>
                                <Title level={5}>
                                    {
                                        ((!profile.first_name || profile.first_name.length === 0) &&
                                            (!profile.last_name || profile.last_name.length === 0)) ?
                                            <div className="aappoint-secondary-color">N/A</div> :
                                            (profile.first_name && profile.first_name.length > 0) ?
                                                (profile.last_name && profile.last_name.length > 0) ?
                                                    <div>{`${profile.first_name} ${profile.last_name}`}</div> :
                                                    <div>{`${profile.first_name}`}</div> :
                                                <div>{`${profile.last_name}`}</div>
                                    }
                                </Title>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", paddingBottom: "16px" }}>
                                {
                                    profile.role && profile.role.length > 0 ?
                                        <div><SafetyOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{profile.role}</span></div> :
                                        <div className="aappoint-secondary-color"><SafetyOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                                }
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", paddingBottom: "16px" }}>
                                {
                                    profile.email && profile.email.length > 0 ?
                                        <a href={`mailto:${profile.email}`}><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{profile.email}</span></a> :
                                        <div className="aappoint-secondary-color"><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                                }
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", paddingBottom: "16px" }}>
                                {
                                    profile.phone_no && profile.phone_no.length > 0 ?
                                        <a href={`tel:${profile.phone_no}`}><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{profile.phone_no}</span></a> :
                                        <div className="aappoint-secondary-color"><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                                }
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", paddingBottom: "16px" }}>
                                <Button block type="primary" ghost icon={<LockOutlined />} size="large" onClick={(event) => {
                                    setShowChangePasswordModal(true)
                                }}>
                                    Change password
                                </Button>
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", width: "100%", paddingBottom: "16px" }}>
                                <Button block type="primary" icon={<EditOutlined />} size="large" onClick={(event) => {
                                    setShowEditProfileModal(true)
                                }}>
                                    Edit Profile
                                </Button>
                            </div>
                        </div>

                        <div className="aappoint-xs-hidden aappoint-md-flex" style={{ widht: '100%' }}>
                            <div style={{ paddingRight: '24px' }}>
                                {
                                    profile.picture ?
                                        <Avatar size={96} src={profile.picture} className="aappoint-avatar-shadow" /> :
                                        <Avatar size={96} icon={<UserOutlined />} className="aappoint-avatar-shadow" />
                                }
                            </div>
                            <div style={{ flexGrow: "1" }}>
                                <Title level={5}>
                                    {
                                        ((!profile.first_name || profile.first_name.length === 0) &&
                                            (!profile.last_name || profile.last_name.length === 0)) ?
                                            <div className="aappoint-secondary-color">N/A</div> :
                                            (profile.first_name && profile.first_name.length > 0) ?
                                                (profile.last_name && profile.last_name.length > 0) ?
                                                    <div>{`${profile.first_name} ${profile.last_name}`}</div> :
                                                    <div>{`${profile.first_name}`}</div> :
                                                <div>{`${profile.last_name}`}</div>
                                    }
                                </Title>
                                <div style={{ paddingBottom: "16px" }}>
                                    {
                                        profile.role && profile.role.length > 0 ?
                                            <div><SafetyOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{profile.role}</span></div> :
                                            <div className="aappoint-secondary-color"><SafetyOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                                    }
                                </div>
                                <div style={{ paddingBottom: "16px" }}>
                                    {
                                        profile.email && profile.email.length > 0 ?
                                            <a href={`mailto:${profile.email}`}><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{profile.email}</span></a> :
                                            <div className="aappoint-secondary-color"><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                                    }
                                </div>
                                <div style={{ paddingBottom: "16px" }}>
                                    {
                                        profile.phone_no && profile.phone_no.length > 0 ?
                                            <a href={`tel:${profile.phone_no}`}><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{profile.phone_no}</span></a> :
                                            <div className="aappoint-secondary-color"><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                                    }
                                </div>
                            </div>
                            <div className="aappoint-xs-hidden aappoint-md-flex" style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start", paddingLeft: "16px" }}>
                                    <Button
                                        type="primary"
                                        ghost
                                        icon={<LockOutlined />}
                                        style={{ width: "100%" }}
                                        onClick={(event) => {
                                            setShowChangePasswordModal(true)
                                        }}>
                                        Change password
                                    </Button>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start", paddingLeft: "16px" }}>
                                    <Button
                                        type="primary"
                                        icon={<EditOutlined />}
                                        style={{ width: "100%" }}
                                        onClick={(event) => {
                                            setShowEditProfileModal(true)
                                        }}>
                                        Edit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>

            <ChangePasswordModal
                title="Change Password"
                visible={showChangePasswordModal}
                destroyOnClose={true}
                onOk={() => {
                    setShowChangePasswordModal(false)
                }}
                onCancel={() => {
                    setShowChangePasswordModal(false)
                }}
            />
            <ProfileModal
                profile={profile}
                storage={storage}
                currentUser={currentUser}
                title="Edit Profile"
                visible={showEditProfileModal}
                destroyOnClose={true}
                onOk={() => {
                    fetchProfile()
                    setShowEditProfileModal(false)
                }}
                onCancel={() => {
                    setShowEditProfileModal(false)
                }}
            />
        </>
    )
}

export default Profile