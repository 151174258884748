const Roles = [
    {
        value: '',
        label: 'none'
    },
    {
        value: 'admin',
        label: 'admin'
    },
    {
        value: 'finance',
        label: 'finance'
    },
    {
        value: 'user',
        label: 'user'
    },
]

export default Roles;