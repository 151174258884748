import React, { useState, useEffect } from 'react'
import { Avatar, Typography, Table, Tabs, Tag } from "antd"
import { UserOutlined, PhoneOutlined, MailOutlined, ShopOutlined } from '@ant-design/icons'
import { useFirebase } from './firebase/context'
import { notifyError } from './notification'
import { getOwner, listShops } from '../services/api'
import PageHeader from './page-header'
import { shopColumns } from './shops'
import moment from 'moment'

const { Title } = Typography;

const OwnerDetail = ({ ownerId }) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [shopOwner, setShopOwner] = useState(null)
    const [shopsData, setShopsData] = useState({ loading: true, data: null })
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(20)
    const [orderBy, setOrderBy] = useState("created_at")
    const [filter, setFilter] = useState(null)

    useFirebase(firebase => {
        if (!currentUser) {
            firebase.auth().onAuthStateChanged(function (user) {
                setCurrentUser(user)
            });
            return
        }
    }, [])

    useEffect(() => {
        const fetchOwner = async () => {
            if (currentUser && ownerId) {
                try {
                    const token = await currentUser.getIdToken()

                    // If showOwner has the value and the id is the same, don't need to fetch the owner data
                    const ownerResp = await getOwner({ token, id: ownerId })
                    if (ownerResp) {
                        setShopOwner(ownerResp.data)
                    } else {
                        setShopOwner({})
                    }

                    // Fetch the owner's shops
                    const f = filter ? filter : {}
                    f["owner_id"] = ownerId
                    const shopsResp = await listShops({ token, page, pageSize, orderBy, filter: f })
                    if (shopsResp) {
                        setShopsData({ loading: false, data: shopsResp.data })
                    } else {
                        setShopsData({ loading: false, data: { shops: [], total: 0 } })
                    }
                } catch (err) {
                    setShopOwner({})
                    setShopsData({ loading: false, data: { shops: [], total: 0 } })
                    notifyError({ description: "Fail to fetch shop owner information from the server" })
                }
            } else {
                setShopOwner({})
                setShopsData({ loading: false, data: { shops: [], total: 0 } })
            }
        }
        fetchOwner()
    }, [currentUser, ownerId, page, pageSize, orderBy, filter])

    const onTableChange = (pagination, filters, sorter, { currentDataSource, action }) => {
        if (pagination) {
            if (pagination.pageSize !== pageSize) {
                setPageSize(pagination.pageSize)
            }
            if (pagination.current !== page) {
                setPage(pagination.current)
            }
        }
        if (sorter) {
            const { columnKey, order } = sorter;
            if (order && columnKey) {
                const direction = (order === 'descend') ? 'DESC' : ''
                const key = (columnKey === 'name') ? 'name_en' : columnKey
                if (direction) {
                    setOrderBy(key + " " + direction)
                } else {
                    setOrderBy(key)
                }
            } else {
                setOrderBy("created_at")
            }
        }
        if (filters) {
            setFilter(filters)
        }
    }

    const getOwnerName = (owner) => {
        if (!owner) {
            return "N/A"
        }
        return ((!shopOwner.first_name || shopOwner.first_name.length === 0) &&
            (!shopOwner.last_name || shopOwner.last_name.length === 0)) ?
            "N/A" :
            (shopOwner.first_name && shopOwner.first_name.length > 0) ?
                (shopOwner.last_name && shopOwner.last_name.length > 0) ?
                    `${shopOwner.first_name} ${shopOwner.last_name}` :
                    `${shopOwner.first_name}` :
                `${shopOwner.last_name}`
    }

    return (
        <>
            <PageHeader
                title=""
                subTitle=""
                breadcrumb={{
                    routes: [
                        {
                            path: "/app/owners",
                            breadcrumbName: "Owners"
                        },
                        {
                            path: `/app/owners/${ownerId}`,
                            breadcrumbName: `${getOwnerName(shopOwner)}`,
                        }
                    ]
                }}
            />
            <div className="aappoint-page-container">
                {
                    shopOwner &&
                    <div>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div className="aappoint-md-hidden" style={{ display: "flex", flexDirection: "column", justifyContent: "center", width: '100%' }}>
                                <div style={{ display: "flex", paddingBottom: '24px', justifyContent: "center", width: "100%" }}>
                                    {
                                        shopOwner.picture ?
                                            <Avatar size={96} src={shopOwner.picture} className="aappoint-avatar-shadow" /> :
                                            <Avatar size={96} icon={<UserOutlined />} className="aappoint-avatar-shadow" />
                                    }
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "8px" }}>
                                    <small>
                                        <div className="aappoint-secondary-color">
                                            ID: {ownerId}
                                        </div>
                                    </small>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "8px" }}>
                                    <div className="aappoint-secondary-color" style={{ display: "flex", justifyContent: "flex-start", paddingBottom: "4px" }}>
                                        <span style={{ paddingRight: "4px" }}>{(shopOwner && shopOwner.resource_type) ? shopOwner.resource_type : "N/A"}</span>
                                        <span style={{ paddingRight: "4px" }}>|</span>
                                        <span style={{ paddingRight: "4px" }}>{(shopOwner && shopOwner.preferred_lang) ? shopOwner.preferred_lang : "N/A"}</span>
                                    </div>
                                </div>
                                <div style={{ paddingBottom: "0px", display: "flex", justifyContent: "center" }}>
                                    <Title level={3}>{getOwnerName(shopOwner)}</Title>
                                </div>
                                <div style={{ paddingBottom: "8px", display: "flex", justifyContent: "center" }}>
                                    {
                                        shopOwner.email && shopOwner.email.length > 0 ?
                                            <a href={`mailto:${shopOwner.email}`}><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{shopOwner.email}</span></a> :
                                            <div className="aappoint-secondary-color"><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                                    }
                                </div>
                                <div style={{ paddingBottom: "8px", display: "flex", justifyContent: "center" }}>
                                    {
                                        shopOwner.phone_no && shopOwner.phone_no.length > 0 ?
                                            <a href={`tel:${shopOwner.phone_no}`}><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{shopOwner.phone_no}</span></a> :
                                            <div className="aappoint-secondary-color"><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                                    }
                                </div>

                            </div>
                            <div className="aappoint-xs-hidden aappoint-md-flex" style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                <div style={{ paddingRight: '24px' }}>
                                    {
                                        shopOwner.picture ?
                                            <Avatar size={96} src={shopOwner.picture} className="aappoint-avatar-shadow" /> :
                                            <Avatar size={96} icon={<UserOutlined />} className="aappoint-avatar-shadow" />
                                    }
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", flexGrow: "1" }}>
                                    <div style={{ paddingBottom: "0px" }}>
                                        <Title level={3}>{getOwnerName(shopOwner)}</Title>
                                    </div>
                                    <div className="aappoint-secondary-color" style={{ paddingBottom: "8px" }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start", paddingBottom: "4px" }}>
                                            <span style={{ paddingRight: "4px" }}>ID:</span>
                                            <span style={{ paddingRight: "4px" }}>{ownerId}</span>
                                        </div>
                                    </div>
                                    <div className="aappoint-secondary-color" style={{ paddingBottom: "8px" }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start", paddingBottom: "4px" }}>
                                            <span style={{ paddingRight: "4px" }}>{(shopOwner && shopOwner.resource_type) ? shopOwner.resource_type : "N/A"}</span>
                                            <span style={{ paddingRight: "4px" }}>|</span>
                                            <span style={{ paddingRight: "4px" }}>{(shopOwner && shopOwner.preferred_lang) ? shopOwner.preferred_lang : "N/A"}</span>
                                        </div>
                                    </div>
                                    <div style={{ paddingBottom: "8px" }}>
                                        {
                                            shopOwner.email && shopOwner.email.length > 0 ?
                                                <a href={`mailto:${shopOwner.email}`}><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{shopOwner.email}</span></a> :
                                                <div className="aappoint-secondary-color"><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                                        }
                                    </div>
                                    <div style={{ paddingBottom: "8px" }}>
                                        {
                                            shopOwner.phone_no && shopOwner.phone_no.length > 0 ?
                                                <a href={`tel:${shopOwner.phone_no}`}><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{shopOwner.phone_no}</span></a> :
                                                <div className="aappoint-secondary-color"><PhoneOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                                        }
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start" }}>
                                    {
                                        /* 
                                        Actions
                                        */
                                    }
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingTop: "24px", width: "100%" }}>
                            <Tabs defaultActiveKey="1">
                                <Tabs.TabPane
                                    tab={
                                        <span>
                                            <ShopOutlined />
                                            Shops
                                        </span>
                                    }
                                    key="1"
                                >
                                    <Table
                                        dataSource={shopsData.data ? shopsData.data.shops : []}
                                        columns={shopColumns}
                                        rowKey="id"
                                        onChange={onTableChange}
                                        pagination={{
                                            size: "small",
                                            total: (shopsData.data) ? shopsData.data.total : 0,
                                            current: page,
                                            pageSize: pageSize,
                                            showLessItems: true,
                                            showSizeChanger: true,
                                        }}
                                    />
                                </Tabs.TabPane>
                            </Tabs>
                        </div>
                        <div style={{ paddingTop: "24px", width: "100%", display: 'flex', justifyContent: 'flex-end' }}>
                            {
                                (shopOwner) &&
                                <div>
                                    <div className="aappoint-secondary-color"><small>Created at: {moment(shopOwner.created_at).format("DD/MM/YYYY HH:mm:ss")}</small></div>
                                    <div className="aappoint-secondary-color"><small>Last update: {moment(shopOwner.updated_at).format("DD/MM/YYYY HH:mm:ss")}</small></div>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

export default OwnerDetail;