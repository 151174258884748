import React, { useState, useCallback } from 'react'
import { Button, Menu, Dropdown, Modal, Radio } from 'antd'
import {
    DownloadOutlined,
    DownOutlined,
    PrinterOutlined,
    EditOutlined,
    RocketOutlined,
    StopOutlined,
    AuditOutlined,
    DollarCircleOutlined,
    FileDoneOutlined,
    SendOutlined
} from '@ant-design/icons'
import { useFirebase } from './firebase/context'
import { notifyError } from './notification'
import { getInvoice, getInvoiceHTML, getInvoicePDF, getInvoiceStatus, setInvoiceStatus } from '../services/api'
import PageHeader from './page-header'
import { invoicePrefix } from './invoices'
import EditInvoiceModal from './modals/edit-invoice-modal'
import SendInvoiceModal from './modals/send-invoice-modal'
import DOMPurify from 'dompurify'
// import printJS from 'print-js'
import moment from 'moment'

var fileDownload = require('js-file-download');

const zeroPad = (num, places) => String(num).padStart(places, '0')

const InvoiceDetail = ({ invoiceId }) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [invoice, setInvoice] = useState(null)
    const [invoiceHTML, setInvoiceHTML] = useState(null)
    const [showEditInvoiceModal, setShowEditInvoiceModal] = useState(false)
    const [showSendInvoiceModal, setShowSendInvoiceModal] = useState(null)
    const [loadingPrint, setLoadingPrint] = useState(false)
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [language, setLanguage] = useState("en")

    const fetchInvoice = useCallback(async () => {
        if (currentUser && invoiceId) {
            try {
                const token = await currentUser.getIdToken()
                const resp = await getInvoice({ token, id: invoiceId })
                if (resp) {
                    setInvoice(resp.data)
                } else {
                    setInvoice(null)
                }

                const respHTML = await getInvoiceHTML({ token, id: invoiceId, lang: language })
                if (respHTML && respHTML.data) {
                    const sanitized = DOMPurify.sanitize(respHTML.data)
                    setInvoiceHTML(sanitized)
                } else {
                    setInvoiceHTML(null)
                }
            } catch (err) {
                console.error(err)
                setInvoice(null)
                setInvoiceHTML(null)
                notifyError({ description: "Fail to fetch invoice information from the server" })
            }
        } else {
            setInvoice(null)
            setInvoiceHTML(null)
        }
    }, [currentUser, invoiceId, language])

    useFirebase(firebase => {
        if (!currentUser) {
            firebase.auth().onAuthStateChanged(function (user) {
                setCurrentUser(user)
            });
            return
        }
        fetchInvoice()
    }, [fetchInvoice])

    const invoiceNum = invoicePrefix + zeroPad(invoiceId, 9)

    const downloadPDF = async () => {
        if (currentUser && invoiceId) {
            setLoadingDownload(true)
            try {
                const token = await currentUser.getIdToken()
                const response = await getInvoicePDF({ token, id: invoiceId, lang: (language) ? language : "en" })
                setLoadingDownload(false)
                if (response) {
                    fileDownload(response.data, `${invoiceNum}.pdf`)
                }
            } catch (err) {
                setLoadingDownload(false)
                console.error(err)
                notifyError({ description: "Fail to download the invoice (pdf)" })
            }
        }
    }

    const printInvoice = async () => {
        var divContents = document.getElementById("invoicePage").innerHTML;
        var a = window.open('', '');
        a.document.write('<html>');
        a.document.write('<body style="font-size: 14px; width: 21cm; line-height: 1.6;">');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();

        // if (currentUser && invoiceId) {
        //     setLoadingPrint(true)
        //     try {
        //         const token = await currentUser.getIdToken()
        //         const response = await getInvoicePDF({ token, id: invoiceId, lang: (language) ? language : "en" })
        //         setLoadingPrint(false)
        //         if (response) {
        //             const blobURL = URL.createObjectURL(response.data);
        //             printJS(blobURL);
        //         }
        //     } catch (err) {
        //         setLoadingPrint(false)
        //         notifyError({ description: "Fail to download the invoice (pdf)" })
        //     }
        // }
    }

    const onEditInvoice = () => {
        setShowEditInvoiceModal(true)
    }

    const onSendInvoice = () => {
        setShowSendInvoiceModal(true)
    }

    const onReview = async () => {
        Modal.confirm({
            title: "Mark as Reviewed",
            content: "Are you sure to mark this invoice as reviewed?",
            icon: <FileDoneOutlined />,
            onOk: async () => {
                try {
                    const token = await currentUser.getIdToken()
                    await setInvoiceStatus({ token, id: invoiceId, status: 'reviewed' })
                    await fetchInvoice()
                } catch (err) {
                    notifyError({ description: "Fail to mark invoice as reviewed" })
                }
            },
        })
    }

    const onApprove = async () => {
        Modal.confirm({
            title: "Approve Invoice",
            content: "Are you sure to mark this invoice as approved?",
            icon: <AuditOutlined />,
            onOk: async () => {
                try {
                    const token = await currentUser.getIdToken()
                    await setInvoiceStatus({ token, id: invoiceId, status: 'approved' })
                    await fetchInvoice()
                } catch (err) {
                    notifyError({ description: "Fail to mark invoice as approved" })
                }
            },
        })
    }

    const onMarkAsPaid = async () => {
        Modal.confirm({
            title: "Make as Paid",
            content: "Are you sure to mark this invoice as paid?",
            icon: <DollarCircleOutlined />,
            okType: 'danger',
            onOk: async () => {
                try {
                    const token = await currentUser.getIdToken()
                    await setInvoiceStatus({ token, id: invoiceId, status: 'paid' })
                    await fetchInvoice()
                } catch (err) {
                    notifyError({ description: "Fail to mark invoice as paid" })
                }
            },
        })
    }

    const onVoid = async () => {
        Modal.confirm({
            title: "Void Invoice",
            content: "Are you sure to void this invoice?",
            icon: <StopOutlined />,
            okType: 'danger',
            onOk: async () => {
                try {
                    const token = await currentUser.getIdToken()
                    await setInvoiceStatus({ token, id: invoiceId, status: 'voided' })
                    await fetchInvoice()
                } catch (err) {
                    notifyError({ description: "Fail to mark invoice as voided" })
                }
            },
        })
    }

    const actionMenu = (
        (invoice) ?
            <Menu>
                {
                    (invoice.status === 'created') &&
                    <Menu.Item key="reviewed">
                        <div className="aappoint-secondary-color" onClick={onReview} style={{ height: '36px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', paddingLeft: '8px', paddingRight: '8px' }}>
                            <div style={{ width: '24px' }}><FileDoneOutlined style={{ fontSize: '14px' }} /></div>
                            <div style={{ width: '128px' }}>Mark as Reviewed</div>
                        </div>
                    </Menu.Item>
                }
                {
                    (invoice.status === 'reviewed') &&
                    <Menu.Item key="approved">
                        <div className="aappoint-secondary-color" onClick={onApprove} style={{ height: '36px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', paddingLeft: '8px', paddingRight: '8px' }}>
                            <div style={{ width: '24px' }}><AuditOutlined style={{ fontSize: '14px' }} /></div>
                            <div style={{ width: '128px' }}>Approve</div>
                        </div>
                    </Menu.Item>
                }
                {
                    (invoice.status === 'approved') &&
                    <Menu.Item key="paid">
                        <div className="aappoint-secondary-color" onClick={onMarkAsPaid} style={{ height: '36px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', paddingLeft: '8px', paddingRight: '8px' }}>
                            <div style={{ width: '24px' }}><DollarCircleOutlined style={{ fontSize: '14px' }} /></div>
                            <div style={{ width: '128px' }}>Mark as Paid</div>
                        </div>
                    </Menu.Item>
                }
                {
                    (invoice.status !== 'voided') &&
                    <Menu.Item key="void">
                        <div className="aappoint-danger-color" onClick={onVoid} style={{ height: '36px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', paddingLeft: '8px', paddingRight: '8px' }}>
                            <div style={{ width: '24px' }}><StopOutlined style={{ fontSize: '14px' }} /></div>
                            <div style={{ width: '128px' }}>Void</div>
                        </div>
                    </Menu.Item>
                }
                {
                    (invoice.status !== 'voided') &&
                    <Menu.Divider key="sep1" />
                }
                <Menu.Item key="send">
                    {
                        (invoice.status === 'created') || (invoice.status === 'reviewed') || (invoice.status === 'voided') ?
                            <div className="aappoint-disabled-text" style={{ height: '36px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', paddingLeft: '8px', paddingRight: '8px' }}>
                                <div style={{ width: '24px' }}><SendOutlined style={{ fontSize: '14px' }} /></div>
                                <div style={{ width: '128px' }}>Send</div>
                            </div> :
                            <div className="aappoint-secondary-color" onClick={onSendInvoice} style={{ height: '36px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', paddingLeft: '8px', paddingRight: '8px' }}>
                                <div style={{ width: '24px' }}><SendOutlined style={{ fontSize: '14px' }} /></div>
                                <div style={{ width: '128px' }}>Send</div>
                            </div>
                    }
                </Menu.Item>
                <Menu.Item key="print">
                    {
                        (invoice.status === 'created') || (invoice.status === 'reviewed') ?
                            <div className="aappoint-disabled-text" style={{ height: '36px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', paddingLeft: '8px', paddingRight: '8px' }}>
                                <div style={{ width: '24px' }}><PrinterOutlined style={{ fontSize: '14px' }} /></div>
                                <div style={{ width: '128px' }}>Print</div>
                            </div> :
                            <div className="aappoint-secondary-color" onClick={printInvoice} style={{ height: '36px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', paddingLeft: '8px', paddingRight: '8px' }}>
                                <div style={{ width: '24px' }}><PrinterOutlined style={{ fontSize: '14px' }} /></div>
                                <div style={{ width: '128px' }}>Print</div>
                            </div>
                    }
                </Menu.Item>
                <Menu.Item key="download">
                    {
                        (invoice.status === 'created') || (invoice.status === 'reviewed') ?
                            <div className="aappoint-disabled-text" style={{ height: '36px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', paddingLeft: '8px', paddingRight: '8px' }}>
                                <div style={{ width: '24px' }}><DownloadOutlined style={{ fontSize: '14px' }} /></div>
                                <div style={{ width: '128px' }}>Download</div>
                            </div> :
                            <div className="aappoint-secondary-color" onClick={downloadPDF} style={{ height: '36px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'row', paddingLeft: '8px', paddingRight: '8px' }}>
                                <div style={{ width: '24px' }}><DownloadOutlined style={{ fontSize: '14px' }} /></div>
                                <div style={{ width: '128px' }}>Download</div>
                            </div>
                    }
                </Menu.Item>
            </Menu> : <></>
    )

    const onChangePreviewLanguage = async (e) => {
        setLanguage(e.target.value)
    }

    return (
        <>
            <PageHeader
                title=""
                subTitle=""
                breadcrumb={{
                    routes: [
                        {
                            path: "/app/invoices",
                            breadcrumbName: "Invoices"
                        },
                        {
                            path: `/app/invoices/${invoiceId}`,
                            breadcrumbName: `${invoiceNum}`,
                        }
                    ]
                }}
            />
            {invoice &&
                <div className="aappoint-page-container" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="aappoint-xs-hidden aappoint-md-flex" style={{ justifyContent: 'space-between', width: '100%', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start', width: '100%', alignItems: 'center' }}>
                            <span style={{ paddingRight: "16px" }}>
                                <Radio.Group onChange={onChangePreviewLanguage} defaultValue="en">
                                    <Radio.Button value="en">EN</Radio.Button>
                                    <Radio.Button value="th">TH</Radio.Button>
                                </Radio.Group>
                            </span>
                            <span className="aappoint-secondary-color">Status:&nbsp;</span>
                            {
                                (invoice.status === 'reviewed') ?
                                    <span className="aappoint-warning-color">{invoice.status}</span> :
                                    (invoice.status === 'approved') ?
                                        <span className="aappoint-secondary-color">{invoice.status}</span> :
                                        (invoice.status === 'paid') ?
                                            <span className="aappoint-success-color">{invoice.status}</span> :
                                            (invoice.status === 'voided') ?
                                                <span className="aappoint-danger-color">{invoice.status}</span> :
                                                <span className="aappoint-secondary-color">{invoice.status}</span>
                            }

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', flexDirection: 'row' }}>
                            <div style={{ paddingLeft: '16px' }}>
                                <Dropdown overlay={actionMenu}>
                                    <Button
                                        type="primary"
                                        ghost
                                        icon={<RocketOutlined />}
                                        loading={loadingPrint || loadingDownload}>
                                        Action<DownOutlined />
                                    </Button>
                                </Dropdown>
                            </div>
                            <div style={{ paddingLeft: '16px' }}>
                                <Button
                                    type="primary"
                                    icon={<EditOutlined />}
                                    disabled={(invoice.status !== 'created') && (invoice.status !== 'reviewed')}
                                    onClick={onEditInvoice}
                                >
                                    Edit
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="aappoint-md-hidden" style={{ display: 'flex', width: '100%', justifyContent: "space-between" }}>
                        <Radio.Group onChange={onChangePreviewLanguage} defaultValue="en">
                            <Radio.Button value="en">EN</Radio.Button>
                            <Radio.Button value="th">TH</Radio.Button>
                        </Radio.Group>
                        <div>
                            <span className="aappoint-secondary-color">Status:&nbsp;</span>
                            {
                                (invoice.status === 'reviewed') ?
                                    <span className="aappoint-warning-color">{invoice.status}</span> :
                                    (invoice.status === 'approved') ?
                                        <span className="aappoint-secondary-color">{invoice.status}</span> :
                                        (invoice.status === 'paid') ?
                                            <span className="aappoint-success-color">{invoice.status}</span> :
                                            (invoice.status === 'voided') ?
                                                <span className="aappoint-danger-color">{invoice.status}</span> :
                                                <span className="aappoint-secondary-color">{invoice.status}</span>
                            }
                        </div>
                    </div>
                    <div className="aappoint-md-hidden" style={{ display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'column' }}>
                        <div style={{ paddingTop: '16px' }}>
                            <Dropdown overlay={actionMenu}>
                                <Button
                                    type="primary"
                                    ghost
                                    icon={<RocketOutlined />}
                                    block
                                    size='large'
                                    loading={loadingPrint || loadingDownload}>
                                    Action<DownOutlined />
                                </Button>
                            </Dropdown>
                        </div>
                        <div style={{ paddingTop: '16px', paddingBottom: '16px' }}>
                            <Button
                                type="primary"
                                icon={<EditOutlined />}
                                block
                                size='large'
                                disabled={(invoice.status !== 'created') && (invoice.status !== 'reviewed')}
                                onClick={onEditInvoice}
                            >
                                Edit
                            </Button>
                        </div>
                    </div>
                    <div
                        style={{ display: 'flex', justifyContent: 'center', width: '100%', paddingTop: '16px' }}
                    >
                        <div id="invoicePage" style={{
                            backgroundColor: 'white',
                            maxWidth: '21cm',
                            minHeight: '29.7cm',
                            display: 'flex',
                            justifyContent: 'center',
                            boxShadow: '0px 0px 5px rgba(0,0,0,0.3)',
                            padding: '25.4mm 20mm 20mm 20mm',
                            margin: '2px 2px 2px 2px',
                            position: 'relative'
                        }}
                        >
                            <div dangerouslySetInnerHTML={{ __html: invoiceHTML }} />
                            {/* <div style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {
                                    (invoice.status === 'paid') &&
                                    <div style={{ fontSize: '240px', color: 'rgba(0,196,0,0.3)' }}><strong>PAID</strong></div>
                                }
                                {
                                    (invoice.status === 'voided') &&
                                    <div style={{ fontSize: '240px', color: 'rgba(255,0,0,0.3)' }}><strong>VOID</strong></div>
                                }
                            </div> */}
                        </div>
                    </div>
                    {
                        (invoice) &&
                        <div style={{ paddingTop: "24px", width: "100%", display: 'flex', justifyContent: 'flex-end' }}>
                            <div>
                                <div className="aappoint-secondary-color"><small>Created at: {moment(invoice.created_at).format("DD/MM/YYYY HH:mm:ss")}</small></div>
                                <div className="aappoint-secondary-color"><small>Last update: {moment(invoice.updated_at).format("DD/MM/YYYY HH:mm:ss")}</small></div>
                            </div>
                        </div>
                    }

                </div>
            }
            <EditInvoiceModal
                currentUser={currentUser}
                title="Edit Invoice"
                visible={showEditInvoiceModal}
                invoice={invoice}
                destroyOnClose={true}
                onOk={() => {
                    setShowEditInvoiceModal(false)
                    fetchInvoice()
                }}
                onCancel={() => {
                    setShowEditInvoiceModal(false)
                }}
            />
            <SendInvoiceModal
                currentUser={currentUser}
                title="Send Invoice"
                visible={showSendInvoiceModal}
                invoice={invoice}
                language={language}
                destroyOnClose={true}
                onOk={() => {
                    setShowSendInvoiceModal(false)
                }}
                onCancel={() => {
                    setShowSendInvoiceModal(false)
                }}
            />
        </>
    )
}

export default InvoiceDetail;