import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/layout"
import PrivateRoute from "../components/private-route"
import Profile from "../components/profile"
import Dashboard from '../components/dashboard'
import Invoices from '../components/invoices'
import Shops from '../components/shops'
import Owners from '../components/owners'
import OwnerDetail from '../components/owner-detail'
import Users from '../components/users'
import Settings from '../components/settings'
import ShopDetail from '../components/shop-detail'
import InvoiceDetail from '../components/invoice-detail'

const App = () => (
    <Layout showSider={true}>
        <Router>
            <PrivateRoute path="/app/profile" component={Profile} />
            <PrivateRoute path="/app/dashboard" component={Dashboard} />
            <PrivateRoute path="/app/invoices" component={Invoices} />
            <PrivateRoute path="/app/invoices/:invoiceId" component={InvoiceDetail} />
            <PrivateRoute path="/app/shops" component={Shops} />
            <PrivateRoute path="/app/shops/:shopId" component={ShopDetail} />
            <PrivateRoute path="/app/owners" component={Owners} />
            <PrivateRoute path="/app/owners/:ownerId" component={OwnerDetail} />
            <PrivateRoute path="/app/users" component={Users} />
            <PrivateRoute path="/app/settings" component={Settings} />
        </Router>
    </Layout>
)
export default App