import React, { useState } from 'react'
import { Modal, Form, DatePicker, Avatar, Button, Input, AutoComplete } from 'antd'
import { FileAddOutlined, ShopOutlined } from '@ant-design/icons'
import { generateInvoice, listShops } from '../../services/api'
import { notifySuccess, notifyError } from '../notification'
import moment from 'moment'
import { navigate } from 'gatsby'

const NewInvoiceModal = ({ currentUser, onOk, onCancel, ...rest }) => {
    const [loading, setLoading] = useState(false)
    const [serviceMonth, setServiceMonth] = useState(moment().startOf('month'))
    const [form] = Form.useForm();
    const [shopOptions, setShopOptions] = useState([])
    const [selectedShop, setSelectedShop] = useState(null)

    const onFinish = async () => {
        if (currentUser && selectedShop) {
            setLoading(true)
            try {
                const month = parseInt(serviceMonth.format("M"))
                const year = parseInt(serviceMonth.format("YYYY"))
                const token = await currentUser.getIdToken()
                const resp = await generateInvoice({ token, shop_id: selectedShop.id, month, year })
                notifySuccess({ description: "Invoice has been generated successfully" })
                setLoading(false)
                if (onOk) {
                    onOk();
                }
                if (resp && resp.data && resp.data.id) {
                    navigate(`/app/invoices/${resp.data.id}`)
                }
            } catch (err) {
                setLoading(false)
                const { message } = (err.response && err.response.data) ? err.response.data : {}
                if (message === 'Inactive shop with zero transaction') {
                    notifyError({ description: message })
                } else if (message === 'There is an existing active invoice with the same service month') {
                    notifyError({ description: message })
                }
                else {
                    notifyError({ description: "Fail to generate an invoice" })
                }
            }
        }
    }

    const handleOnCancel = () => {
        setSelectedShop(null)
        form.resetFields()
        if (onCancel) {
            onCancel();
        }
    }

    const onServiceMonthChange = (date) => {
        setServiceMonth(date)
    }

    const renderShopTitle = (shop) => {
        const avatar = (shop.logo) ?
            <Avatar src={shop.logo} size={32} /> :
            <Avatar icon={<ShopOutlined />} size={32} />
        const title = (shop) ? (shop.name_en) ? shop.name_en : (shop.name_th) ? shop.name_th : "" : ""
        const subTitle = (shop && shop.name_en && shop.name_th) ? shop.name_th : ""

        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                    {avatar}
                </div>
                <div style={{ display: "flex", flexDirection: 'column', flexGrow: "1", paddingLeft: '8px', paddingRight: '8px' }}>
                    <div>{title}</div>
                    <div className="aappoint-secondary-color"><small>{subTitle}</small></div>
                </div>
                <div className="aappoint-secondary-color">
                    ID: {shop.id}
                </div>
            </div>
        )
    }

    const onShopAutoCompleteSearch = async (value) => {
        setSelectedShop(null)
        if (currentUser) {
            const filter = (value) ? {
                shop: value,
            } : undefined
            try {
                const token = await currentUser.getIdToken()
                const resp = await listShops({
                    token,
                    page: 1,
                    pageSize: 10,
                    orderBy: "shop.name_en",
                    filter,
                })
                const options = ((resp) && (resp.data) && (resp.data.shops)) ?
                    resp.data.shops.map(shop => {
                        const title = (shop) ? (shop.name_en) ? shop.name_en : (shop.name_th) ? shop.name_th : "" : ""
                        return {
                            label: renderShopTitle(shop),
                            value: title,
                            key: shop.id,
                            shop
                        }
                    }) : []
                setShopOptions(options)
            } catch (err) {
                setShopOptions([])
            }
        }
    }

    const onShopAutoCompleteSelect = async (value, option) => {
        if (option && option.shop) {
            setSelectedShop(option.shop)
        } else {
            setSelectedShop(null)
        }
    }

    const onBlurShop = () => {
        console.log("BLUR SHOP")
        form.validateFields(["shop"])
    }

    const disabledDate = (current) => {
        return current && current > moment().endOf('day');
    }

    return (
        <Modal
            onOk={form.submit}
            onCancel={handleOnCancel}
            footer={[
                <Button key="cancel" onClick={handleOnCancel}>
                    Cancel
                </Button>,
                <Button key="ok" type="primary" loading={loading} onClick={form.submit}>
                    OK
                </Button>,
            ]}
            {...rest}
        >
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <div style={{ paddingBottom: "16px" }}>
                    <Avatar size={96} icon={<FileAddOutlined />} className="aappoint-avatar-shadow .aappoint-avatar-default" />
                </div>
            </div>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="shop"
                    label="Shop"
                    rules={[
                        {
                            required: true,
                            message: 'Please select the shop to generate an invoice'
                        },
                        {
                            validator: async (_, value) => {
                                if (!selectedShop) {
                                    throw new Error("Please select the shop to generate an invoice")
                                }
                            }
                        }
                    ]}
                >
                    <AutoComplete
                        defaultActiveFirstOption={false}
                        options={shopOptions}
                        onSelect={onShopAutoCompleteSelect}
                        onSearch={onShopAutoCompleteSearch}
                        onBlur={onBlurShop}
                    >
                        <Input
                            placeholder="Enter a shop name or a shop ID to search for a shop"
                            prefix={
                                (selectedShop && selectedShop.logo) ?
                                    <Avatar src={selectedShop.logo} size={24} /> :
                                    <ShopOutlined className="site-form-item-icon" size={24} />
                            }
                            suffix={
                                <div className="aappoint-secondary-color">{(selectedShop) ? `ID: ${selectedShop.id}` : ""}</div>
                            }
                        />
                    </AutoComplete>
                </Form.Item>
                <Form.Item
                    name="service_month"
                    label="Service month"
                    rules={[{ required: true, message: 'Please select the service month to generate an invoice' }]}
                >
                    <DatePicker
                        value={serviceMonth}
                        onChange={onServiceMonthChange}
                        disabledDate={disabledDate}
                        picker="month"
                        style={{ width: "100%" }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default NewInvoiceModal;