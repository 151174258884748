import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Avatar, Button, Upload } from 'antd'
import { UserOutlined, SafetyOutlined, MailOutlined, CameraOutlined } from '@ant-design/icons'
import ImgCrop from 'antd-img-crop';
import { setProfile as apiSetProfile } from '../../services/api'
import { notifySuccess, notifyError } from '../notification'
import { v4 as uuidv4 } from 'uuid';

const ProfileModal = ({ currentUser, storage, profile, onOk, onCancel, visible, ...rest }) => {
    const [loading, setLoading] = useState(false)
    const [fileList, setFileList] = useState([]);
    const [photoFile, setPhotoFile] = useState(null);
    const [form] = Form.useForm();

    useEffect(() => {
        if (profile && visible) {
            form.setFieldsValue({ first_name: profile.first_name, last_name: profile.last_name, phone_no: profile.phone_no });
        }
    }, [form, profile, visible])


    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const onFinish = async (values) => {
        if (currentUser) {
            setLoading(true)
            try {
                var picture = profile.picture
                if (photoFile && storage) {
                    const ext = (/[.]/.exec(photoFile.name)) ? /[^.]+$/.exec(photoFile.name) : undefined;
                    const filename = (ext) ? uuidv4() + "." + ext : uuidv4()
                    const pathRef = storage.ref(`/users/${currentUser.uid}/picture/${filename}`)
                    await pathRef.put(photoFile)
                    picture = await pathRef.getDownloadURL()

                    // Delete old picture if exist
                    if (profile.picture) {
                        await storage.refFromURL(profile.picture).delete()
                    }
                }

                const token = await currentUser.getIdToken()
                const { first_name, last_name } = values
                const phone_no = (values.phone_no) ?
                    (values.phone_no.substring(0, 1) === '+') ? values.phone_no :
                        (values.phone_no.substring(0, 1) === '0') ? values.phone_no.replace("0", "+66") : "+66" + values.phone_no : undefined

                await apiSetProfile({
                    token,
                    profile: {
                        first_name,
                        last_name,
                        phone_no,
                        picture,
                    }
                })

                notifySuccess({ description: "Your profile has been updated successfully" })
                setPhotoFile(null)
                setLoading(false)
                if (onOk) {
                    onOk();
                }
            } catch (err) {
                setLoading(false)
                if (err.response && err.response.data && err.response.data.message && err.response.status >= 400 && err.response.status < 500) {
                    notifyError({ description: "Fail to update your profile.\n" + err.response.data.message })
                } else {
                    notifyError({ description: "Fail to update your profile" })
                }
            }
        }
    }

    const handleOnCancel = () => {
        setPhotoFile(null);
        form.resetFields();
        if (onCancel) {
            onCancel();
        }
    }

    const onUploadRequest = async ({ onSuccess, file }) => {
        setPhotoFile(file)
        onSuccess(null)
    }

    const onRemovePhoto = () => {
        setPhotoFile(null)
        return true
    }

    return (
        <Modal
            onOk={form.submit}
            onCancel={handleOnCancel}
            visible={visible}
            footer={[
                <Button key="cancel" onClick={handleOnCancel}>
                    Cancel
                </Button>,
                <Button key="ok" type="primary" loading={loading} onClick={form.submit}>
                    OK
                </Button>,
            ]}
            {...rest}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <div style={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column', display: 'flex' }}>
                    <div style={{ position: "relative" }}>
                        {
                            (photoFile) ? <Avatar size={96} src={URL.createObjectURL(photoFile)} className="aappoint-avatar-shadow" /> :
                                (profile && profile.picture) ? <Avatar size={96} src={profile.picture} className="aappoint-avatar-shadow" /> :
                                    <Avatar size={96} icon={<UserOutlined />} className="aappoint-avatar-shadow" />
                        }

                        <div style={{ bottom: "-4px", right: "-4px", position: "absolute" }}>
                            <ImgCrop rotate>
                                <Upload
                                    action={`${process.env.GATSBY_AAPPOINT_BACKOFFICE_API_BASE_URL}/profile/picture`}
                                    method="put"
                                    fileList={fileList}
                                    onChange={onChange}
                                    onPreview={onPreview}
                                    withCredentials={true}
                                    showUploadList={false}
                                    customRequest={onUploadRequest}
                                    onRemove={onRemovePhoto}
                                    accept=".png,.jpg,.jpeg,image/png,image/jpeg"
                                >
                                    <Button
                                        type="primary"
                                        shape="circle"
                                        icon={<CameraOutlined />}
                                        style={{ borderWidth: "2px", borderColor: "white", boxShadow: "0px 0px 5px #88888833" }}
                                    />
                                </Upload>
                            </ImgCrop>
                        </div>
                    </div>
                    <div style={{ paddingBottom: "16px", paddingTop: "16px" }}>
                        {
                            profile && profile.role && profile.role.length > 0 ?
                                <div><SafetyOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{profile.role}</span></div> :
                                <div className="aappoint-secondary-color"><SafetyOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                        }
                    </div>
                    <div style={{ paddingBottom: "16px" }}>
                        {
                            profile && profile.email && profile.email.length > 0 ?
                                <a href={`mailto:${profile.email}`}><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>{profile.email}</span></a> :
                                <div className="aappoint-secondary-color"><MailOutlined size={24} /><span style={{ paddingLeft: "4px" }}>N/A</span></div>
                        }
                    </div>
                </div>
                <Form.Item
                    name="first_name"
                    label="First name"
                >
                    <Input placeholder="Enter your first name" />
                </Form.Item>
                <Form.Item
                    name="last_name"
                    label="Last name"
                >
                    <Input placeholder="Enter your last name" />
                </Form.Item>
                <Form.Item
                    name="phone_no"
                    label="Phone"
                >
                    <Input placeholder="Enter your phone number" />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ProfileModal;